@use 'sass:math';

.calculating-form {
  @include flex-column;

  .category-subform {
    position: relative;
    padding: rem(25px 30px);
    background: var(--white);
    box-shadow: rem(0px 3px 7px) rgba(0, 0, 0, 0.09);
    border-radius: rem(8px);

    > .col {
      border-bottom: rem(1px) solid var(--primary-border-color);
    }

    &__item {
      @include flex-column;

      > span {
        display: block;
        font-size: rem(14px);
        line-height: rem(27px);
        font-weight: 600;
        color: var(--active-side-menu-background-color);
        margin-bottom: rem(5px);
      }

      + span.error {
        font-size: rem($default-font-size);
        font-weight: 600;
      }
    }

    &__btn-add {
      position: absolute;
      bottom: 0;
      transform: translate3d(0, 50%, 0);
      right: rem($gutter-size);
    }

    &:not([class*='mb-20']) {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      + .category-subform {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .weight-subform {
    .weight-price {
      font-size: rem(16px);
      line-height: rem(24px);
      color: #1d2023;
      font-weight: 500;
      white-space: nowrap;
    }

    .responsive-price {
      display: none !important;

      @include mq($until: tablet) {
        display: block !important;
      }
    }

    .value-box {
      width: 100%;

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input:focus {
        border: rem(1px) solid var(--footer-navigation-links);
      }

      p {
        margin: 0;
        font-weight: 600;
        font-size: rem(18px);
        line-height: rem(28px);
        color: var(--secondary-text-color);

        span {
          display: none;

          @include mq($until: tablet) {
            display: block;
          }
        }

        @include mq($until: tablet) {
          display: flex;
        }
      }

      .value-input {
        margin-top: rem(10px);
        background: var(--white);
        font-family: $default-font;
        border: rem(1px) solid var(--footer-navigation-links);
        box-sizing: border-box;
        border-radius: rem(8px);
        max-width: rem(100px);
        width: 100%;
        height: rem(47px);
        color: #020202;
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(24px);
        padding-left: rem(18px);
        outline: none;

        @include mq($until: tablet) {
          display: none;
        }
      }

      .value-input-responsive {
        display: none;
        background: var(--white);
        font-family: $default-font;
        border: rem(1px) solid var(--footer-navigation-links);
        box-sizing: border-box;
        border-radius: rem(8px);
        max-width: rem(70px);
        width: 100%;
        height: rem(30px);
        color: #020202;
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(24px);
        padding-left: rem(18px);
        outline: none;

        @include mq($until: tablet) {
          display: block;
          border: none;
          padding-left: rem(5px);
          margin-left: rem(5px);
        }
      }
    }

    .range-box {
      @include flex-space-between;
      height: 100%;
      align-items: center;
      padding-top: rem(25px);
      margin-left: rem($space-md-size);

      > div:nth-child(1) {
        width: 100%;
      }

      .semantic_ui_range_inner {
        margin: rem(0px 20px 0px 0px) !important;
        width: 100%;

        div:nth-child(1) {
          background-color: var(--footer-navigation-links) !important;
        }

        div:nth-child(2) {
          background-color: var(--primary-breadcrumbs-color) !important;
        }

        div:nth-child(3)::after {
          display: block;
          content: 'kg';
          width: rem(34px);
          height: rem(34px);
          border-radius: 50%;
          background-color: var(--active-side-menu-background-color);
          color: var(--white);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: rem(14px);
          line-height: rem(20px);
          font-weight: 500;
          position: absolute;
          bottom: rem(-7px);
          left: rem(-7px);
        }
      }
    }
    .range-box.limit-slider .semantic_ui_range_inner div:nth-child(3)::after {
      content: 'gr';
    }
  }

  .calc-button {
    cursor: pointer;
    max-width: rem(145px);
    width: 100%;
    height: rem(55px);
    background-color: var(--primary-side-menu-color);
    box-shadow: rem(0px 3px 7px) rgba(0, 0, 0, 0.09);
    border-radius: rem(6px);
    color: var(--white);
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(24px);
    outline: none;
    border: none;
    margin-top: rem(20px);

    @include mq($until: tablet) {
      max-width: 100%;
    }
  }

  .calc-button:hover {
    background-color: var(--footer-background-color);
  }

  .form__weight {
    .weight-text {
      top: rem(43px);
    }
  }
  .total-price-result {
    width: 100%;
    max-height: 100%;
    background: var(--white);
    border: rem(1px) solid var(--footer-navigation-links);
    border-radius: rem(6px);
    padding-right: rem($space-md-size);
    padding-bottom: rem($space-md-size);
    padding-left: rem($space-md-size);

    @include mq($until: desktop) {
      padding-left: rem($space-md-size);
    }

    @include mq($until: tablet) {
      margin: rem(20px 0px 20px 0px);
    }

    @include mq($from: small-desktop) {
      padding-left: rem(300px);
    }

    p {
      margin: rem(0px);
    }

    .weight {
      font-family: $second-font;
      color: var(--primary-text-color);
      font-size: rem(16px);
      line-height: rem(18px);
      max-width: rem(510px);
      width: 100%;
      height: auto;
      @include flex-space-between;
      margin-top: rem(23px);

      @include mq($until: desktop) {
        max-width: 100%;
      }
    }

    .additional {
      font-family: $second-font;
      color: var(--primary-text-color);
      font-size: rem(16px);
      line-height: rem(18px);
      margin-top: rem($space-md-size);
      max-width: rem(510px);
      width: 100%;
      @include flex-space-between;

      @include mq($until: desktop) {
        max-width: 100%;
      }
    }

    .total {
      font-weight: 600;
      color: var(--primary-text-color);
      font-size: rem(18px);
      line-height: rem(28px);
      letter-spacing: 0.005em;
      margin-top: rem(21px);
      max-width: rem(510px);
      width: 100%;
      @include flex-space-between;

      @include mq($until: desktop) {
        max-width: 100%;
      }
    }
  }
  .result-container {
    margin-top: rem($space-md-size);
    background: var(--white);
    border: rem(1px) solid var(--footer-navigation-links);
    border-radius: rem(6px);
    padding: rem(0px);
    max-width: 100%;
    width: 100%;

    .transfer-system {
      min-height: rem(56px);
      padding: rem(17px 20px);
      display: flex;
      color: var(--secondary-text-color);
      font-weight: 500;
      font-size: rem(14px);
      line-height: rem(22px);
      border-bottom: rem(2px) solid var(--card-background-2);

      p {
        margin: 0;
        width: 50%;
      }
    }

    .money-gram {
      height: rem(56px);
      padding: rem(17px 20px);
      display: flex;
      font-family: $second-font;
      align-items: center;
      justify-content: space-between;
      border-bottom: rem(1px) solid var(--primary-border-color);

      p {
        margin: rem(0px);
        width: 50%;
        font-weight: 400;
        color: var(--secondary-text-color);
      }

      p:nth-child(1) {
        color: var(--active-side-menu-background-color);
        font-size: rem(14px);
        line-height: rem(18px);
        font-weight: 600;
      }
    }

    .contact {
      height: rem(56px);
      padding: rem(17px 20px);
      @include flex-center-between;
      font-family: $second-font;

      p {
        margin: rem(0px);
        width: 50%;
        font-weight: 400;
        color: var(--secondary-text-color);
      }

      p:nth-child(1) {
        color: var(--active-side-menu-background-color);
        font-size: rem(14px);
        line-height: rem(18px);
        font-weight: 600;
      }
    }
  }
}
