.lead-card {
  @include flex-space-between;
  height: rem(230px);
  background: var(--white);
  overflow: hidden;
  border: 1px solid var(--primary-border-color);
  border-radius: rem(6px);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);

  &__left {
    @include flex-space-between;
    flex-direction: column;
    align-items: flex-start;
    max-width: rem(160px);
    margin: rem($space-lg-size);
  }

  &__title {
    margin: 0;
    margin-bottom: rem($space-xs-size);
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(24px);
    line-height: rem(30px);
    color: var(--primary-side-menu-color);
  }

  &__position {
    margin: 0;
    font-weight: 400;
    font-size: rem(16px);
    font-family: $default-font;
    line-height: rem(24px);
    color: var(--primary-text-color);
  }

  &__social-network {
    @include flex-center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__social-item:not(:last-child) {
    margin-right: rem(8px);
  }

  &__social-link {
    @include flex-center;
    width: rem(40px);
    height: rem(40px);
    font-size: rem(20px);
    color: $icon-background-color-2;
    background-color: var(--white);
    border: 1px solid var(--footer-navigation-links);
    border-radius: 50%;

    &:hover {
      color: var(--white);
      background-color: $icon-stroke-color-4;
      border: none;
      box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.09);
    }
  }

  &__link-to-cv {
    margin-top: rem(8px);
    display: inline-block;
  }

  &__right {
    height: rem(300px);
    width: rem(300px);
    position: relative;
  }

  &__background {
    @include flex-center;
    height: 100%;
    width: 100%;
    font-size: rem(100px);
    color: var(--footer-navigation-links);
    background-position: center !important;
    background-repeat: no-repeat;
    background: $background-gradient-5;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    position: absolute;
    top: rem(-40px);
  }
}

@include mq($from: tablet) {
  .first .lead-card {
    &__title {
      min-width: rem(400px);
    }
  }
}
