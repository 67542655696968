:root {
  --black: #{$black};
  --primary-text-color: #{$primary-text-color};
  --secondary-text-color: #{$secondary-text-color};
  --primary-breadcrumbs-color: #{$primary-breadcrumbs-color};
  --active-breadcrumbs-color: #{$active-breadcrumbs-color};
  --primary-side-menu-color: #{$primary-side-menu-color};
  --active-side-menu-background-color: #{$active-side-menu-background-color};
  --active-side-menu-border-color: #{$active-side-menu-border-color};
  --footer-background-color: #{$footer-background-color};
  --footer-navigation-links: #{$footer-navigation-links};
  --footer-navigation-title: #{$footer-navigation-title};
  --text-color-1: #{$text-color-1};
  --text-color-2: #{$text-color-2};
  --text-color-3: #{$text-color-3};
  --card-background-1: #{$card-background-1};
  --card-background-2: #{$card-background-2};
  --card-background-3: #{$card-background-3};
  --card-background-4: #{$card-background-4};
  --card-background-5: #{$card-background-5};
  --card-background-6: #{$card-background-6};
  --card-background-7: #{$card-background-7};
  --section-background-1: #{$section-background-1};
  --section-background-2: #{$section-background-2};
  --section-background-3: #{$section-background-3};
  --primary-border-color: #{$primary-border-color};
  --secondary-border-color: #{$secondary-border-color};
  --third-border-color: #{$third-border-color};
  --white: #{$white};
  --icon-stroke-color-2: #{$icon-stroke-color-2};
  --icon-stroke-color-3: #{$icon-stroke-color-3};
  --icon-stroke-color-6: #{$icon-stroke-color-6};
  --icon-stroke-color-10: #{$icon-stroke-color-10};
  --icon-stroke-color-11: #{$icon-stroke-color-11};
  --icon-stroke-color-12: #{$white};
  --input-placeholder: #{$input-placeholder};

  --point-green: #{$point-green};
  --point-red: #{$point-red};
  --point-light-yellow: #{$point-light-yellow};
  --point-orange: #{$point-orange};
  --point-grey: #{$point-grey};
  --point-blue: #{$point-blue};
  --point-yellow: #{$point-yellow};
  --point-finder: #{$point-finder};

  --green-gradient: #{$green-gradient};
  --light-green-gradient: #{$light-green-gradient};
  --red-gradient: #{$red-gradient};
  --orange-gradient: #{$orange-gradient};
  --grey-gradient: #{$grey-gradient};
  --blue-gradient: #{$blue-gradient};

  --stock-null: #{$stock-null};
  --stock-full: #{$stock-full};
  //example => // --primary-color: var(--primary-text-color);

  --light-gray-400: #dde2e4;
  --gray-400: #6e7c87;
}

.theme--alternative {
  --black: #{$active-breadcrumbs-color};
  --primary-text-color: #{$active-breadcrumbs-color};
  --secondary-text-color: #{$active-breadcrumbs-color};
  --primary-breadcrumbs-color: #{$active-breadcrumbs-color};
  --active-breadcrumbs-color: #{$active-breadcrumbs-color};
  --primary-side-menu-color: #{$footer-navigation-title};
  --active-side-menu-background-color: #{$active-breadcrumbs-color};
  --active-side-menu-border-color: #{$active-breadcrumbs-color};
  --footer-background-color: #{$third-border-color};
  --footer-navigation-links: #{$black};
  --footer-navigation-title: #{$active-breadcrumbs-color};
  --text-color-1: #{$active-breadcrumbs-color};
  --text-color-2: #{$text-color-2};
  --text-color-3: #{$text-color-3};
  --card-background-1: #{$primary-border-color};
  --card-background-2: #{$primary-border-color};
  --card-background-3: #{$primary-border-color};
  --card-background-4: #{$primary-border-color};
  --card-background-5: #{$third-border-color};
  --card-background-6: #{$primary-border-color};
  --card-background-7: #{$primary-border-color};
  --section-background-1: #{$primary-border-color};
  --section-background-2: #{$primary-border-color};
  --section-background-3: #{$active-breadcrumbs-color};
  --primary-border-color: #{$primary-border-color};
  --secondary-border-color: #838383;
  --third-border-color: #{$third-border-color};
  --icon-stroke-color-2: #{$active-breadcrumbs-color};
  --icon-stroke-color-3: #{$active-breadcrumbs-color};
  --icon-stroke-color-6: #{$active-breadcrumbs-color};
  --icon-stroke-color-10: #{$active-breadcrumbs-color};
  --icon-stroke-color-11: #{$black};
  --icon-stroke-color-12: #{$active-breadcrumbs-color};
  --input-placeholder: #{$footer-navigation-title};

  --point-green: #{$active-breadcrumbs-color};
  --point-red: #{$active-breadcrumbs-color};
  --point-light-yellow: #{$active-breadcrumbs-color};
  --point-orange: #{$active-breadcrumbs-color};
  --point-grey: #{$active-breadcrumbs-color};
  --point-blue: #{$active-breadcrumbs-color};
  --point-yellow: #{$active-breadcrumbs-color};
  --point-finder: #{$active-breadcrumbs-color};

  --green-gradient: #{$background-gradient-2};
  --light-green-gradient: #{$background-gradient-2};
  --red-gradient: #{$background-gradient-2};
  --orange-gradient: #{$background-gradient-2};
  --grey-gradient: #{$background-gradient-2};
  --blue-gradient: #{$background-gradient-2};

  --stock-null: #{$active-breadcrumbs-color};
  --stock-full: #{$active-breadcrumbs-color};

  img {
    filter: grayscale(1);
  }
  svg {
    filter: grayscale(1);
  }
  .gallery__image,
  .gallery-dots,
  .track-trace__block,
  .section-text__content,
  .administration-card__right,
  .lead-card__background,
  .section-history__image,
  .upload-more-button,
  .carousel-banner__background,
  .navigation-settings__item,
  .section-bg__image,
  .section-image__history,
  .section-image__presentation,
  .news-banner__image,
  .slider-card__img-block,
  .section-image__news,
  .bottom-content__image {
    filter: grayscale(1);
  }
}
