.track-trace {
  > .row {
    border: 1px solid $primary-border-color;
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
    border-radius: rem(8px);
    overflow: hidden;
  }

  &__block {
    min-height: rem(160px);
    background-size: cover;
    padding: 0;

    &-cover {
      @include flex-center;
      height: 100%;
      width: 100%;
      font-size: rem(36px);
      font-weight: 700;
      text-align: center;
      color: var(--white);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.25) 100%);
    }
  }

  &__input {
    @include flex-center;

    &.search-box {
      margin: 0;
      padding: rem(0 39px);
      height: 100%;
      max-width: 100%;

      .search-box__container {
        padding: rem(39px 0);
      }
    }

    .input-icon {
      left: rem(18px);
    }

    .search-button {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: rem(0 18px);

      svg {
        margin-right: rem(10px);
      }
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-left: rem(44px);
    }

    .search-button,
    .input__container input {
      height: rem(56px);
    }

    .input-icon + .input__container input {
      padding-left: rem($space-md-size * 3);
    }
  }
}
