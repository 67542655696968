.nav-link {
  @include flex-center;
  height: rem(83px);
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--primary-border-color);
  border-radius: rem(4px);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);

  &:hover {
    background: var(--card-background-2);
  }
}

.active {
  background: var(--card-background-2);
  box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.09);
}
