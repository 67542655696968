.ecommerce-card {
  border-radius: rem(8px);
  padding: rem(16px);
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid var(--primary-border-color);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
  transition: $transition-time;
  font-family: $default-font;

  &:hover {
    box-shadow: none;
  }

  &__header {
    position: relative;
    padding: rem(24px);
    border-radius: rem(8px);
    background-color: $section-background-2;
    margin-bottom: rem(4px);
    flex: 0 0 auto;
    height: rem(200px);
    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__body {
    margin-bottom: rem(16px);
    flex: 1 1 auto;
    &-title {
      font-size: rem(14px);
      color: var(--primary-text-color);
      word-break: break-word;
    }
    &-description {
      font-size: rem(12px);
      color: var(--secondary-text-color);
      word-break: break-word;
      max-height: 5rem;
      overflow-y: hidden;
      position: relative;
      padding-top: 0.2rem;
      height: 100%;
      &::after {
        content: '';
        height: 3rem;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg, transparent, #fff);
        pointer-events: none;
      }
    }
  }

  &__footer {
    margin-top: auto;
    margin-bottom: 0;
    &-price {
      color: var(--active-side-menu-background-color);
      font-weight: 600;
      font-size: rem(18px);
      margin-bottom: rem(8px);
      width: 100%;
      &.discount {
        text-decoration: line-through;
        color: var(--footer-navigation-title);
        margin-right: rem(12px);
      }
    }
  }

  &__stock {
    position: absolute;
    padding: 0 rem(8px);
    border-radius: rem(16px);
    color: var(--white);
    font-size: rem(12px);
    top: 0.5rem;
    left: 0.5rem;
    &-full {
      background-color: var(--stock-full);
    }
    &-null {
      background-color: var(--stock-null);
    }
  }
}
