.filter-collapse {
  .collapse {
    border: none;
    background-color: transparent;
    &-header {
      background-color: $white;
      border-radius: rem(8px);
      padding: rem($space-xs-size $space-md-size);
    }
    &-body {
      padding: 0;
      padding-top: rem(30px);
    }
  }
}
