.news-section {
  width: 100%;
}

.news-section__header {
  .news-box {
    align-items: center;

    &__title {
      margin: 0;
      padding: rem(8px 0);
      font-weight: 700;
      font-size: rem(36px);
      line-height: rem(48px);
      color: var(--secondary-text-color);
    }

    &__item {
      margin-top: rem(4px);
      margin-bottom: rem(4px);

      &:not(:last-child) {
        margin-right: rem(10px);
      }
    }

    &__filter {
      @include flex-center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: rem(21px);
      margin-bottom: rem(4px);
      @include mq($from: tablet) {
        @include flex-center-end;
        flex-wrap: nowrap;
        margin: 0;
      }

      .filter-button {
        height: rem(38px);

        &__text {
          margin: 0;
          font-family: $default-font;
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(24px);
          white-space: nowrap;
        }
      }
    }
  }
}
.news-section__footer {
  .upload-more-button {
    height: rem(55px);
    &__text {
      margin: 0;
      font-family: $default-font;
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(24px);
      white-space: nowrap;
    }
  }
}

.box-news {
  &__link {
    @include flex-center-vert;
  }
  &__subtitle {
    margin: 0;
    margin-right: rem($space-xs-size);
    font-weight: 700;
    font-size: rem(16px);
    line-height: rem(28px);
    color: var(--active-side-menu-background-color);
  }

  &__button {
    @include flex-center;
    height: rem(28px);
    width: rem(28px);
    font-size: rem(14px);
    background-color: var(--card-background-4);
    border-radius: 50%;
    transition: background-color $transition-time;
    z-index: 4;
    cursor: pointer;

    path {
      fill: none;
      stroke: $icon-stroke-color-1;
      transition: stroke $transition-time;
    }
  }
  &__button:hover {
    background-color: $icon-background-color-1;

    path {
      stroke: var(--white);
    }
  }
}
