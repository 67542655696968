.input-phone__container {
  .input-phone {
    .input.form-control {
      width: 100%;
      height: rem(47px);
      padding: rem($space-xs-size 18px $space-xs-size 50px);
      font-family: $default-font;
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(24px);
      color: var(--primary-text-color);
      background-color: var(--white);
      border-color: var(--footer-navigation-links);
    }
    .input.form-control:focus {
      box-shadow: 0 0 0 3px rgb(0 116 217 / 20%);
    }
    .dropdown.country-list {
      width: rem(315px);
      .search.input-search {
        .search-box.input-search-box {
          width: calc(100% - 8px);
          height: rem(30px);
          margin: 0;
        }
      }
    }
  }
  span.error {
    font-family: $second-font;
    font-weight: 600;
    font-size: rem($default-font-size);
    line-height: rem(18px);
  }
}
