.navigation-tooltip {
  width: 100%;
  border-radius: rem(6px);
  border: rem(1px) solid rgb(236, 236, 236);
  height: auto;
  background-color: var(--white);
  display: flex;
  font-family: $default-font;
  padding: rem($space-lg-size $space-lg-size 0);
  box-shadow: inset -1px 0px 0px var(--primary-border-color);
  margin-top: rem(5px);

  &.is-loading {
    padding-bottom: rem($space-lg-size);
  }

  &__theme {
    max-width: rem(310px);
    min-width: rem(250px);
    width: 100%;

    .theme-links {
      margin-bottom: rem($space-lg-size);

      .title {
        display: block;
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(22px);
        color: var(--active-side-menu-background-color);
        padding: rem(5px 0 5px 10px);
        margin: rem(-5px 0 -5px -10px);

        &.active {
          background-color: var(--active-side-menu-background-color);
          color: $white;

          &:hover {
            color: var(--active-side-menu-background-color);
          }
        }

        &:hover {
          background-color: rgba($active-side-menu-background-color, 0.1);
        }
      }

      .theme-item-links {
        ul {
          list-style: none;
          margin: rem(0);
          padding: rem(0);

          li {
            font-weight: 500;
            color: var(--primary-side-menu-color);
            font-size: rem($space-md-size);
            line-height: rem(24px);
            padding: rem(0 0 0 10px);
            margin: rem($space-xs-size 0 0 -10px);
            cursor: pointer;
            transition: background-color $transition-time;

            &.active {
              background-color: unset;
              box-shadow: unset;
              color: var(--active-side-menu-background-color);
              border-right: 3px solid var(--active-side-menu-border-color);
            }

            &:hover {
              background-color: rgba($active-side-menu-background-color, 0.1);
            }
          }

          li + li {
            margin-top: rem($space-xs-size);
          }
        }

        ul li::before {
          content: '\2022';
          color: var(--footer-navigation-title);
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-right: rem($space-xs-size);
        }
      }
    }
  }
}
