@import './SearchFilters.scss';
@import './SelectFilters.scss';
@import './CollapseFilters.scss';

.filter-container-design {
  font-family: $default-font;
  padding: rem($space-xs-size $space-md-size);
  background-color: $white;
  border-radius: rem(8px);
  margin-bottom: rem(30px);
  &.no-margin {
    margin-bottom: 0;
  }
  &__title {
    font-weight: 500;
    font-size: rem(15px);
    color: $primary-side-menu-color;
    margin-top: rem(8px);
  }
}
