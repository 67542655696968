.comment-box {
  width: 100%;
  height: 100%;
  padding: rem(25px);
  background-color: var(--card-background-1);
  border: rem(1px) solid var(--primary-border-color);
  border-radius: rem(8px);

  &__title {
    margin-bottom: rem($space-xs-size);
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(24px);
    color: var(--active-side-menu-background-color);
  }

  &__text {
    font-family: $second-font;
    font-weight: 400;
    font-size: rem($default-font-size);
    line-height: rem(21px);
    color: var(--text-color-2);

    a {
      color: var(--active-side-menu-background-color);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
