.info-box {
  @include flex;
  margin-top: rem(30px);
  padding: rem($space-lg-size);
  background-color: var(--card-background-1);
  border: 1px solid var(--primary-border-color);
  border-radius: rem(6px);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);

  &__title-text {
    margin: 0;
    margin-left: rem(12px);
    font-weight: 700;
    font-size: rem($default-font-size);
    line-height: rem(21px);
    color: var(--secondary-text-color);
  }

  &__text {
    font-weight: 400;
  }
}
