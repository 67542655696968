@use 'sass:math';

.module-translate {
  position: fixed;
  bottom: rem($gutter-size);
  left: rem($gutter-size);
  z-index: 3;

  .pm-tooltip__trigger .button {
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
  }

  .button {
    @include flex-center;
    font-size: rem(50px);
    background-color: #91beff;
    padding: 0;
    transition: background-color $transition-time;

    svg path {
      transition: fill $transition-time;
    }

    &:hover {
      background-color: #2e78f0;

      svg path {
        fill: #91beff;
      }
    }
  }

  .button:hover &--count {
    color: #91beff;
  }

  &--count {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: rem($default-font-size);
    color: #2e78f0;
    transform: translate3d(-50%, -50%, 0);
  }

  &__tooltip {
    background-color: $white;
    margin-bottom: rem(5px);
    border-radius: rem(8px);
    min-width: rem(260px);
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
    max-height: calc(100vh - #{rem(140px)});
    overflow: hidden auto;

    .layout__sider-menu .sidebar-container {
      box-shadow: none;
      border-radius: 0;
    }

    .collapse {
      border-radius: 0;

      ul {
        margin: 0;
        padding: 0;

        li {
          border-top: 1px solid var(--primary-border-color);
        }
      }

      &-body {
        padding: 0;
      }
    }

    .input__container {
      margin-left: math.div(-$gutter-size, 2);
      margin-right: $gutter-size;

      input {
        border: none;
        padding: rem(0 math.div($gutter-size, 2));
      }

      &,
      input {
        max-height: rem($base-size);
      }
    }

    .layout__sider-menu .sidebar-container__item-children ul li {
      min-height: rem(55px);
      justify-content: space-between;
    }
  }

  &__btn {
    padding: rem(math.div($gutter-size, 4) math.div($gutter-size, 2));
    padding-left: rem(5px);
  }

  &__actions {
    @include flex;
    justify-content: space-between;
    padding: rem(math.div($gutter-size, 2));
  }

  &__empty {
    @include flex-center;
    padding: rem(math.div($gutter-size, 2));
  }
}
