.filter-select {
  &__list {
    list-style: none;
    padding: 0;
    margin-left: 0 !important;
    &-item {
      margin-bottom: rem(10px);
      &:last-child {
        margin-bottom: 0;
      }
      &__checkbox-label {
        color: $footer-navigation-title;
        font-family: $default-font;
        input[type='checkbox'] {
          border: 1px solid $fourth-border-color;
          background-color: var(--section-background-2);
        }
      }
    }
  }
}
