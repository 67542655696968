@use 'sass:math';

.language-switcher-tooltip {
  display: block;
  color: var(--footer-navigation-links);
  font-size: rem(14px);
  font-weight: 500;
  line-height: rem(18px);
  padding: 0;
  background-color: var(--white);
  border-radius: rem(5px);
  list-style: none;
  width: 100%;
  margin-top: rem(5px);
  border: rem(1px) solid rgb(236, 236, 236);

  @include mq($until: small-desktop) {
    @include flex-self-center;
    margin: 0;
    width: 100%;
    border: none;
    background-color: var(--section-background-2);
  }
  &__item {
    font-family: $default-font;
    color: var(--text-color-2);
    font-size: rem(14px);
    font-weight: 500;
    line-height: rem(18px);
    height: rem(40px);
    width: 100%;
    cursor: pointer;
    padding: rem($space-xs-size $space-lg-size $space-xs-size $space-lg-size);

    &:hover {
      background-color: var(--footer-background-color);
      color: var(--white);
    }

    @include mq($until: small-desktop) {
      @include flex-center;
      width: 100%;
      padding: rem($space-xs-size $space-xs-size 48px);
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(28px);
      background: var(--section-background-2);

      box-shadow: inset 0px -1px 0px var(--primary-border-color);
      border-radius: rem(4px);

      a {
        display: inline-flex;
        align-items: center;
        border: 1px solid transparent;
        height: rem(48px);
        padding: rem(10px);
        margin: rem($gutter-size);
        box-shadow: inset 0px -1px 0px $primary-border-color;
        border-radius: rem(4px);

        svg {
          margin-right: rem(math.div($gutter-size, 2));
        }
      }

      &:hover {
        color: var(--text-color-2);
        background-color: transparent;
      }

      a.is-active,
      &:hover a {
        background-color: var(--card-background-6);
        border: 1px solid var(--primary-border-color);
        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
        border-radius: rem(6px);
      }
    }
  }

  &__item:nth-child(1) {
    @include mq($from: small-desktop) {
      border-top-left-radius: rem(4px);
      border-top-right-radius: rem(4px);
    }
  }

  &__item:nth-child(3) {
    @include mq($from: small-desktop) {
      border-bottom-left-radius: rem(4px);
      border-bottom-right-radius: rem(4px);
    }
  }
}

.footer-language-switcher .language-switcher-tooltip {
  margin-left: rem($space-lg-size);
}

.rotate {
  transform: rotate(180deg);
}
