.padded-image {
  padding: rem(20px);
  background-color: white;
  border-radius: 8px;
  width: 100%;

  > * {
    width: 100%;
    height: 100%;
  }
}
