@import './SearchInput.scss';
@import './InputPhone.scss';

.input__container {
  font-family: $second-font;
  font-weight: 600;
  font-size: rem($default-font-size);
  line-height: rem(18px);
  color: var(--primary-text-color);

  input {
    width: 100%;
    height: rem(47px);
    padding: rem($space-xs-size $space-md-size);
    font-family: $default-font;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: var(--primary-text-color);
    background-color: var(--white);
    border: 1px solid var(--footer-navigation-links);
    border-radius: rem(8px);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::placeholder {
    color: var(--input-placeholder);
  }

  input:focus,
  textarea:focus {
    border: 1px solid var(--footer-navigation-links);
    box-shadow: 0 0 0 3px rgb(0 116 217 / 20%);
    outline: none;
  }

  &.is-disabled {
    input {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}

.input__label {
  margin-bottom: rem(8px);
  font-family: $default-font;
  font-weight: 600;
  font-size: rem(16px);
  line-height: rem(27px);
  color: var(--active-side-menu-background-color);

  span {
    color: red;
  }
}
