@use 'sass:math';

// Animations
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loaderFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader {
  position: relative;
  z-index: 2;

  &,
  .fade-in {
    animation: $animation-base;
    transition: opacity $transition-time;
  }

  &-label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    + .loader__spinner {
      margin-left: rem(-$base-size);
    }
  }

  &__inline {
    color: var(--active-side-menu-background-color);
    font-family: $default-font;
    font-weight: 400;
    @include flex-center;
  }

  &__inline.color-white &__spinner {
    border-top-color: $card-background-2;
  }

  &__inline &__spinner {
    position: relative;
    display: inline-block;
    margin-right: rem(7.5px);
    top: 0;
  }

  & > &__spinner {
    position: absolute;
  }

  & > &__spinner.fixed {
    position: fixed;
  }

  &__spinner {
    width: rem($base-size);
    height: rem($base-size);
    border-radius: 50%;

    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &--small {
      border: 2px solid var(--active-side-menu-background-color);
      border-top: 2px solid var(--footer-navigation-links);
      width: math.div($base-size, 2);
      height: math.div($base-size, 2);
      top: calc(50% - #{math.div($base-size, 4)});
    }

    &--middle {
      border: 2px solid var(--active-side-menu-background-color);
      border-top: 2px solid var(--footer-navigation-links);
      width: rem($base-size);
      height: rem($base-size);
      top: calc(50% - #{math.div($base-size, 2)});
      left: calc(50% - #{math.div($base-size, 2)});
    }

    &--regular {
      border: 6px solid var(--active-side-menu-background-color);
      border-top: 6px solid var(--footer-navigation-links);
      width: rem($base-size * 2);
      height: rem($base-size * 2);
      top: calc(50% - #{$base-size});
      left: calc(50% - #{$base-size});
    }
  }
}
