.alert {
  @include flex-center-vert;
  background-color: var(--card-background-1);
  border-radius: rem(8px);
  padding: rem($space-md-size);

  &__info--icon {
    display: block;
    margin-right: rem($space-md-size);
    font-size: rem($space-xxl-size);
    color: var(--active-breadcrumbs-color);
    opacity: 30%;
  }

  &__content {
    width: 100%;
    font-family: $default-font;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: $black;
    align-self: center;
  }
}
