$white: #fff !default;
$black: #000 !default;

$primary-text-color: #222 !default;
$secondary-text-color: #070707 !default;

$primary-breadcrumbs-color: #1557a3 !default;
$active-breadcrumbs-color: #6e7482 !default;

$primary-side-menu-color: #0e2f4b !default;

$active-side-menu-background-color: #00529c !default;
$active-side-menu-border-color: #2d87c5 !default;

// Footer
$footer-background-color: #1e5568 !default;
$footer-navigation-links: #d3ddf1 !default;
$footer-navigation-title: #99a2a9 !default;

// Text colors
$text-color-1: #0a3150 !default;
$text-color-2: #020202 !default;
$text-color-3: #1d2023 !default;

// Card colors
$card-background-1: #fff5db !default;
$card-background-2: #fec525 !default;
$card-background-3: #e1f8fa !default;
$card-background-4: #f4f9fe !default;
$card-background-5: #fdc03d !default;
$card-background-6: #ffcc44 !default;
$card-background-7: #a5dfa3 !default;
$card-background-8: #757575 !default;

// Section colors
$section-background-1: #f3f9f7 !default;
$section-background-2: #f8f8f8 !default;
$section-background-3: #ffb017 !default;

// Border colors
$primary-border-color: #eef1f2 !default;
$secondary-border-color: #487484 !default;
$third-border-color: #d0d3d8 !default;
$fourth-border-color: #d4d8dd !default;
$primary-error-color: #ff0000;

// Icon
$icon-stroke-color-1: #334b89 !default;
$icon-stroke-color-2: #3393a3 !default;
$icon-stroke-color-3: #228aa8 !default;
$icon-stroke-color-4: #68727c !default;
$icon-stroke-color-5: #1557a3 !default;
$icon-stroke-color-6: #0072bb !default;
$icon-stroke-color-7: #3d6ad6 !default;
$icon-stroke-color-8: #41a1f2 !default;
$icon-stroke-color-9: #0073b1 !default;
$icon-stroke-color-10: #ff0000 !default;
$icon-stroke-color-11: #c5cacf !default;

$icon-background-color-1: rgba(0, 0, 0, 0.3) !default;
$icon-background-color-2: #0f3947 !default;

//Input
$input-placeholder: #2c5271;

// Gradient
$background-gradient-1: linear-gradient(270deg, rgba(0, 0, 0, 0) 28.72%, rgba(0, 0, 0, 0.29) 100%);
$background-gradient-2: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
$background-gradient-3: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
$background-gradient-4: linear-gradient(270deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
$background-gradient-5: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%), #e7f3ff;

//Map point color
$point-blue: #3d84c2 !default;
$point-green: #299b94 !default;
$point-red: #e62414 !default;
$point-light-yellow: #ffdf00 !default;
$point-orange: #ff8744 !default;
$point-grey: #a39075 !default;
$point-yellow: #fec525 !default;
$point-finder: #08dd39 !default;

$green-gradient: linear-gradient(0deg, #a2d18c, #b3d9a1);
$light-green-gradient: linear-gradient(0deg, #fff5db, #fbf7ec);
$red-gradient: linear-gradient(0deg, #e62414, #e93829);
$orange-gradient: linear-gradient(0deg, #ff8744, #fdb186);
$grey-gradient: linear-gradient(0deg, #a39075, #d1c6b7);
$blue-gradient: linear-gradient(0deg, #005baa, #005baa);

// E-commerce color
$stock-null: #f2271c;
$stock-full: #22c348;
