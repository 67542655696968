.input__container {
  textarea {
    width: 100%;
    min-height: rem(120px);
    padding: rem($space-xs-size 18px);
    font-family: $default-font;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: var(--primary-text-color);
    background-color: var(--white);
    border: 1px solid var(--footer-navigation-links);
    border-radius: rem(8px);
    resize: vertical;
  }
  textarea::placeholder {
    color: var(--input-placeholder);
  }
}
