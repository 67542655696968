.hide {
  display: none;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex {
  @include flex;
}

.align-items-end {
  align-items: flex-end;
}

.justify-items-end {
  justify-content: flex-end;
}
