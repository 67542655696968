.collapse {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--primary-border-color);
  border-radius: rem(8px);
  border-collapse: collapse;

  .collapse-header {
    @include flex-center-between;
    min-height: rem(62px);
    padding: rem($space-md-size 25px);
    cursor: pointer;

    &__right-box,
    &__left-box {
      @include flex-center-vert;
    }

    &__left-box {
      flex: 1 1 100%;
    }

    &__left-box {
      margin-right: rem($space-md-size);
    }

    &__right-box {
      margin-left: rem($space-md-size);
    }

    &__suitcase-icon {
      @include flex;
      font-size: rem(16px);
      margin-right: rem(16px);
    }

    &__title {
      font-family: $default-font;
      font-weight: 600;
      font-size: rem(18px);
      line-height: rem(28px);
      width: 100%;
      color: var(--secondary-text-color);
    }

    &__arrow-icon {
      @include flex-center;
      height: rem(32px);
      width: rem(32px);
      font-size: rem(16px);
      color: var(--primary-side-menu-color);
      background-color: var(--section-background-2);
      border-radius: 50%;
      transition: transform $collapse-transition-time;
    }

    &__parcel-number {
      margin-right: rem(25px);
      font-family: $default-font;
      font-weight: 500;
      font-size: rem($space-md-size);
      line-height: rem(22px);
      color: var(--footer-navigation-title);
    }

    &__data {
      padding: rem(0 55px $space-lg-size 55px);
      font-family: $default-font;
      font-weight: 500;
      font-size: rem($space-md-size);
      line-height: rem(22px);
      color: var(--active-side-menu-background-color);
    }

    &:hover {
      .collapse-header__arrow-icon {
        color: var(--white);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
          var(--active-side-menu-background-color);
      }
    }

    &__arrow-icon--active {
      color: var(--white);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        var(--active-side-menu-background-color);
      transform: rotate(-90deg);
    }
  }

  .collapse-body {
    padding-top: rem($space-xs-size);
    padding-bottom: rem($space-lg-size);
    padding-left: rem($space-xxl-size);
    padding-right: rem($space-xxl-size);
    overflow: hidden;
  }
}
