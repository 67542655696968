.search-result-container {
  .search-results-title {
    font-family: $default-font;
    border-bottom: rem(2px) solid var(--footer-navigation-links);
    padding-bottom: rem(20px);
    margin-bottom: rem(20px);

    p {
      margin: rem(0px);
      color: var(--active-side-menu-background-color);
      font-size: rem(18px);
      line-height: rem(28px);
      font-weight: 600;
      margin-bottom: rem(12px);
    }

    span {
      color: var(--secondary-text-color);
      font-weight: 700;
      font-size: rem(24px);
      line-height: rem(36px);
    }
  }

  .results-list {
    max-width: rem(820px);
    width: 100%;

    &__item {
      max-width: 100%;
      width: 100%;
      border-bottom: rem(1px) solid var(--footer-navigation-links);
      margin-left: rem(0px);

      .item-text {
        cursor: pointer;
        padding-left: rem(73px);

        .title {
          cursor: pointer;
          margin-bottom: rem(10px);
          position: relative;

          a {
            font-weight: 500;
            font-size: rem(20px);
            line-height: rem(32px);
            color: var(--primary-side-menu-color);
          }

          .icon-box {
            width: rem(48px);
            height: rem(48px);
            background-color: var(--white);
            border-radius: 50%;
            position: absolute;
            left: rem(-73px);
            top: rem(0px);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: rem(21px);
              height: rem(19px);
            }
          }
        }

        &:hover .title {
          a {
            color: var(--icon-stroke-color-6);
          }

          .icon-box {
            background-color: var(--card-background-5);

            svg {
              path {
                stroke: var(--primary-text-color);
              }
            }
          }
        }

        .description {
          font-family: $second-font;
          font-weight: 400;
          font-size: rem(14px);
          line-height: rem(24px);
          color: var(--primary-text-color);

          @include mq($until: tablet) {
            display: none;
          }
        }
      }
    }
    .result-list-footer {
      display: flex;
      justify-content: space-between;

      @include mq($until: small-tablet) {
        display: block;
      }

      .pages-count {
        display: block;
        margin-top: rem(20px);
        color: var(--secondary-text-color);
        font-weight: 400;
        font-family: $second-font;
        font-size: rem(14px);
        line-height: rem(24px);
      }

      .pagination-box {
        .pagination {
          margin-top: rem(20px);
          margin-bottom: rem(70px);
        }
      }
    }
  }
}
