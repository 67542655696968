.checkbox__box {
  display: grid;
  column-gap: rem(20px);
  row-gap: rem(15px);

  @include mq($from: small-desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

.checkbox__label {
  display: grid;
  grid-template-columns: 1em auto;
  gap: rem(12px);
  font-family: $second-font;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(18px);
  color: var(--primary-text-color);
  cursor: pointer;
  position: relative;

  input[type='checkbox'] {
    appearance: none;
    background-color: $fourth-border-color;
    margin: 0;
    font: inherit;
    width: rem(18px);
    height: rem(18px);
    border: rem(4px) solid $fourth-border-color;
    border-radius: rem(4px);
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      var(--active-side-menu-background-color);
    border: none;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);
  }

  svg {
    font-size: rem(12px);
    position: absolute;
    top: rem(3px);
    left: rem(3px);
    color: #fff;
  }
}
