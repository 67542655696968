.postal-search-window {
  width: 100%;
  padding: rem($space-md-size);
  font-family: $default-font;
  background-color: var(--white);
  @include mq($until: small-desktop) {
    margin: 0;
  }
  @include mq($from: small-desktop) {
    max-width: rem(530px);
    padding: 0;
    padding-top: rem($space-xl-size);
    border-radius: rem(12px);
    box-shadow: rem(0 12px 19px) rgba(0, 0, 0, 0.09);
    z-index: 99;
    margin-left: 0;
    position: absolute;
    top: rem($space-lg-size);
    left: rem($space-lg-size);
  }

  .active-item {
    border: 1px solid var(--active-side-menu-background-color);
    color: var(--active-side-menu-background-color) !important;
  }

  &__title {
    color: var(--secondary-text-color);

    @include mq($from: small-desktop) {
      padding: 0 rem($space-xxl-size);
    }
  }

  .switcher {
    display: flex;
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 500;
    min-height: rem(38px);

    @include mq($from: small-desktop) {
      margin-top: rem($space-md-size);
      padding: 0 rem($space-xxl-size);
    }

    > div:nth-child(1) {
      margin-right: rem(10px);
    }

    .button {
      color: $card-background-8;
      background-color: var(--white);
      font-family: $default-font;
      font-size: rem(16px);
      font-weight: 500;
      line-height: rem(24px);
    }

    .button:hover {
      color: var(--active-side-menu-background-color);
    }

    .button:first-child {
      margin-right: rem($space-xs-size);
    }
  }

  .filters {
    @include mq($from: small-desktop) {
      padding: 0 rem($space-xl-size);
    }

    &__label {
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(27px);
      color: var(--active-side-menu-background-color);
    }

    .search-cod-input {
      input {
        font-family: $default-font;
        height: rem(47px) !important;
        outline: none;
        font-weight: 500;
        color: var(--active-side-menu-background-color);
      }

      input::placeholder {
        color: var(--input-placeholder);
      }
    }
  }

  .search-street-input {
    position: relative;

    input {
      padding-left: rem(40px);
    }

    svg {
      font-size: rem(18px);
      position: absolute;
      top: rem(5px);
      left: rem(20px);
      transform: translate(-50%, -50%);

      path {
        fill: none;
      }
    }
  }
  .clear-input {
    display: flex;
    cursor: pointer;
    height: rem(47px);
    width: 100%;
    position: relative;
    svg {
      position: absolute;
      top: rem($space-sm-size);
      left: rem(5px);
    }
  }
}

.submit-block {
  .button {
    color: var(--white);
    font-size: rem(16px);
    font-weight: 600;
    outline: none;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      var(--active-side-menu-background-color);
    border-radius: rem(6px);
    border: 1px solid var(--active-side-menu-background-color);
    box-shadow: rem(0 3px 7px) rgba(0, 0, 0, 0.09);
  }
}

.support-block {
  display: none;

  @include mq($from: small-desktop) {
    @include flex-center;
    margin-top: rem(30px);
    height: rem(56px);
    font-family: $second-font;
    background-color: var(--card-background-1);
    border-bottom-left-radius: rem($space-xs-size);
    border-bottom-right-radius: rem($space-xs-size);
    position: relative;
    cursor: pointer;
    transition: background-color $transition-time;

    &:focus,
    &:hover {
      background-color: var(--card-background-2);
    }
  }

  svg {
    margin-right: rem(12px);
    cursor: pointer;
  }

  p {
    color: var(--secondary-text-color);
    line-height: rem(21px);
    font-size: rem($default-font-size);
  }

  .help-message {
    max-width: rem(418px);
    width: 100%;
    height: rem(346px);
    padding: rem(23px $space-lg-size);
    background: var(--white);
    font-family: $second-font;
    border-radius: rem(6px);
    z-index: 5;
    box-shadow: rem(0 12px 19px) rgba(0, 0, 0, 0.09);
    transform: translate(-50%, -50%);
    position: absolute;
    top: rem(-120px);
    left: 140%;

    .arrow-top {
      width: rem(30px);
      height: rem(30px);
      background-color: var(--white);
      transform: rotate(-45deg);
      position: absolute;
      top: 87%;
      left: rem(-10px);
    }

    .close-button {
      cursor: pointer;
      position: absolute;
      top: rem($space-md-size);
      right: rem($space-md-size);

      svg {
        margin: 0;
      }
    }

    &__title {
      margin-top: rem($space-xs-size);
      color: var(--active-side-menu-background-color);
      font-size: rem(14px);
      line-height: rem(21px);
      font-weight: 600;
    }

    &__content {
      margin-top: rem($space-xs-size);
      display: block;
      font-weight: 400;
      font-size: rem($default-font-size);
      line-height: rem(21px);
      color: var(--secondary-text-color);
      white-space: pre-wrap;
    }
  }
}

.clear-button-wrapper {
  display: flex;
  justify-content: flex-end;
  > div {
    height: 100%;
    display: inline-block;
  }
  .button {
    height: 100%;
    width: 3.5rem;
  }
}
