@mixin getResponsiveBreakpoints($map, $key) {
  @each $keyMap, $valueMap in $map {
    .from--#{$keyMap} {
      #{$key}: none;

      @include mq($from: $keyMap) {
        #{$key}: unset;
      }
    }

    .until--#{$keyMap} {
      #{$key}: none;

      @include mq($until: $keyMap) {
        #{$key}: unset;
      }
    }
  }
}

@include getResponsiveBreakpoints($mq-breakpoints, display);
