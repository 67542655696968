.color-yellow {
  color: $card-background-2 !important;
}

.color-white {
  color: $white !important;
}

.color-active-background {
  color: var(--active-side-menu-background-color) !important;
}
