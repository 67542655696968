// button
.button {
  @include flex-center;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-color: transparent;
  background: transparent;
  padding: 0;
  outline: none;
  transition: background-color $transition-time;

  .loader__spinner {
    margin-right: $space-md-size;
  }

  &.button--round {
    border-radius: 100%;
  }

  &--icon {
    padding: 0;
    width: rem(47px);
    height: rem(47px);
  }

  // button type (Color)
  &--primary {
    color: var(--primary-side-menu-color);
    background-color: var(--white);
    border: 1px solid var(--primary-border-color);

    &:hover {
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
    }

    &:active,
    &.active {
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
    }
  }

  &--primary-blue {
    color: var(--primary-side-menu-color);
    background-color: var(--white);
    border: 1px solid var(--primary-border-color);

    &:hover {
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
      border: 1px solid var(--active-side-menu-background-color);
    }

    &:active,
    &.active {
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
    }
  }

  &--dark {
    color: var(--white);
    background-color: var(--primary-side-menu-color);
    border: 1px solid var(--primary-border-color);

    &:hover {
      background-color: var(--footer-background-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
    }

    &:active,
    &.active {
      color: var(--white);
      background-color: var(--footer-background-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
    }
  }

  &--secondary {
    color: $icon-stroke-color-6;
    background-color: var(--section-background-2);
    border: 1px solid $icon-stroke-color-6;

    &:hover {
      color: $icon-stroke-color-5;
      border: 1px solid $icon-stroke-color-5;
    }

    &:active,
    &.active {
      color: $icon-stroke-color-5;
      border: 1px solid $icon-stroke-color-5;
    }
  }

  &--disabled {
    background-color: var(--card-background-4);
    cursor: no-drop;

    .pm-button,
    .pm-icon {
      cursor: not-allowed;
      color: var(--active-breadcrumbs-color);
    }
  }

  // button size
  &--large {
    border-radius: rem($btn-border-radius);
    padding: rem(16px 24px);
  }

  &--medium {
    border-radius: rem($btn-border-radius);
    padding: rem(7px $space-md-size);
  }

  &--small {
    border-radius: rem($btn-border-radius);
    padding: 0;
  }
}
