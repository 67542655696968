.radio-buttons {
  &__title {
    margin-bottom: rem($space-lg-size);
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(27px);
    color: var(--active-side-menu-background-color);

    span {
      color: red;
    }
  }

  &__box {
    @include flex;
  }

  &__label {
    display: grid;
    grid-template-columns: 1em auto;
    gap: rem(12px);
    font-family: $second-font;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(18px);
    color: var(--primary-text-color);
    cursor: pointer;

    input[type='radio'] {
      appearance: none;
      background-color: $fourth-border-color;
      margin: 0;
      font: inherit;
      width: rem(18px);
      height: rem(18px);
      border: rem(4px) solid $fourth-border-color;
      border-radius: 50%;
      cursor: pointer;
    }

    input[type='radio']:checked {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        var(--active-side-menu-background-color);
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);
    }
  }

  &__label:not(:last-child) {
    margin-right: rem(44px);
  }
}
