// Body
$body-padding: 0 !default;
$body-margin: 0 !default;

// Default
$default-font: 'Rubik', sans-serif !default;
$default-font-size: 13px !default;

// Secondary
$second-font: 'Open Sans', sans-serif !default;

// Sass rems
$base-font-size: 17px !default;
$rem-baseline: $base-font-size !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

// Grid
$grid-xxl-size: 1190px !default;
$grid-xl-size: 1000px !default;
$grid-lg-size: 992px !default;
$grid-md-size: 768px !default;
$grid-sm-size: 576px !default;

// Space
$space-from: 0 !default;
$space-to: 80 !default;

$space-xxl-size: 35px !default;
$space-xl-size: 26px !default;
$space-lg-size: 20px !default;
$space-md-size: 15px !default;
$space-sm-size: 13px !default;
$space-xs-size: 10px !default;

// Transitions
$transition-time: 0.3s !default;
$transition-base: all 0.3s ease-in-out !default;
$collapse-transition-time: 1s !default;

// Animations
$animation-base: loaderFade $transition-time forwards !default;

// border-radius
$btn-border-radius: 6px !default;

//size
$base-size: 32px !default;

// Gutters
$gutter-size: 16px !default;

// Box shadow
$box-shadow-margin: 30px !default;
