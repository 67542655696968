.administration-card {
  height: 100%;
  padding: rem($space-lg-size);
  background-color: var(--white);
  overflow: hidden;
  border: 1px solid var(--primary-border-color);
  border-radius: rem(8px);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);

  &__reverse {
    flex-direction: row-reverse;
    align-items: center;
  }

  &__right {
    @include flex-center;
    height: rem(75px);
    width: rem(75px);
    margin: 0;
    font-size: rem(24px);
    color: var(--active-side-menu-background-color);
    @include background-size-cover;
    background: $background-gradient-5;
    border-radius: 50%;
  }
  &__title {
    margin: 0;
    margin-bottom: rem(4px);
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
    color: var(--primary-side-menu-color);
  }

  &__position {
    margin: 0;
    font-weight: 400;
    font-size: rem(14px);
    font-family: $default-font;
    line-height: rem(21px);
    color: var(--primary-text-color);
  }

  &__link-to-cv {
    margin-top: rem(8px);
    display: inline-block;
  }
}
