@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './fonts';
@import './palette';
@import './variables';
@import './mixins';
@import './helpers';

@import '../components';
@import '../pages';

@import './global';

@import './themes.scss';
