.geo-location {
  position: relative;
  width: 100%;
  margin-bottom: rem(-40px);
}

.gm-style-cc {
  display: none;
}

.gm-style {
  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"]
  {
    display: none !important;
  }
}

.map-blur {
  filter: blur(1.5px);
  cursor: not-allowed;

  &:after {
    content: '';
    position: absolute;
    top: rem(0);
    right: rem(0);
    bottom: rem(0);
    left: rem(0);
    margin: auto;

    background-color: var(--white);
    opacity: 0.7;
  }
}

.map-loader {
  cursor: not-allowed;
  filter: blur(4.5px);
  background: url('/MapPreloader.png');
  background-size: cover;
  height: 100%;
  width: 100%;
}

.cluster-pin {
  @include flex-center;
  width: rem(40px);
  height: rem(40px);
  padding: rem($default-font-size);
  color: var(--white);
  background-color: var(--primary-breadcrumbs-color);
  border: rem(1px) solid var(--primary-border-color);
  border-radius: 50%;
  &.finder {
    background-color: var(--point-finder);
  }
}

.pin {
  background-color: var(--active-side-menu-background-color);
  border-radius: 100%;
  width: rem(20px);
  height: rem(20px);
  border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
  border: rem(1px) solid var(--active-side-menu-background-color);
  box-shadow: 1px -2px 5px #99b0c7;
  transform: rotate(135deg);
  position: relative;
  top: rem(-30px);
  cursor: pointer;
}
.pin.pin-post_office {
  background-color: var(--point-blue);
  border: rem(1px) solid var(--point-blue);
}

.pin.pin-post_agency {
  background-color: var(--point-green);
  border: rem(1px) solid var(--point-green);
}

.pin.pin-post_center {
  background-color: var(--point-light-yellow);
  border: rem(1px) solid var(--point-light-yellow);
}

.pin.pin-post_terminal {
  background-color: var(--point-orange);
  border: rem(1px) solid var(--point-orange);
}

.pin.pin-fast_courier_center {
  background-color: var(--point-red);
  border: rem(1px) solid var(--point-red);
}

.pin.pin-transit_center {
  background-color: var(--point-grey);
  border: rem(1px) solid var(--point-grey);
}

.pin.pin-deposit {
  background-color: var(--point-yellow);
  border: rem(1px) solid var(--point-yellow);
}
.map-pin.finder .pin {
  background-color: var(--point-finder);
  border: rem(1px) solid var(--point-finder);
}

.text-info-box {
  @include flex;
  min-width: rem(230px);
  min-height: rem(60px);
  background-color: var(--white);
  border-radius: rem(4px);
  transform: translate3d(-50%, -100%, 0);
  position: relative;
  top: rem(-47px);
  left: rem(-5px);
  z-index: 99;

  .arrow {
    position: absolute;
    bottom: rem(-16px);
    left: 50%;
    width: rem(30px);
    min-height: rem(30px);
    background-color: var(--white);
    border: rem(1px) solid var(--primary-border-color);
    border-top: none;
    border-left: none;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
    z-index: -1;
  }

  &__logo {
    width: rem(60px);
    min-height: rem(60px);
    background: var(--blue-gradient), var(--active-side-menu-background-color);
    border-radius: rem(4px 0px 0px 4px);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: rem(34px);
      height: rem(34px);
    }
  }

  &__link {
    cursor: pointer;
  }

  &__close {
    position: absolute;
    top: rem(5px);
    right: rem(5px);
    cursor: pointer;
  }

  .text-info {
    max-width: 100%;
    min-height: rem(60px);
    padding: rem(9px 8px 9px 12px);
    background-color: var(--white);
    z-index: 2;

    p {
      margin: 0;

      color: var(--active-side-menu-background-color);
      font-size: rem(12px);
      line-height: rem(12px);
      font-weight: 700;
    }
    p:not(:last-child) {
      margin-bottom: rem(2px);
    }

    span {
      display: block;
      margin-top: rem(4px);
      color: var(--secondary-text-color);
      font-weight: 700;
      font-size: rem(11px);
      line-height: rem(14px);
    }
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: calc(100vh - #{rem(130px)});

  @include mq($until: small-desktop) {
    height: calc(100vh - #{rem(200px)});
    border-top: rem(1px) solid var(--primary-border-color);
  }

  @include mq($from: small-desktop) {
    border-right: rem(1px) solid var(--primary-border-color);
  }
}

.map-label {
  left: rem($space-md-size);
  top: rem($space-md-size);
  position: absolute;
  background-color: var(--white);
  box-shadow: rem(0) rem(5px) rem(18.8px) rem(1.2px) rgba(65, 106, 141, 0.16);
  border: rem(1px) solid #eaf0f6;
  border-radius: rem(4px);
  padding: rem(17px) rem($space-lg-size);
  padding-left: rem($space-xxl-size);

  span {
    display: block;
  }

  .anticon {
    position: absolute;
    left: rem($space-md-size);
    top: rem($space-lg-size);
    color: #416a8d;
  }
}

.pin .z-index {
  z-index: 2;
}

.postal-office {
  &__map {
    height: rem(280px);
    > div > div {
      border-radius: rem(8px);
    }
  }
  &__container {
    max-width: rem(820px);

    &--title {
      margin-bottom: rem($space-xs-size);
      font-family: $default-font;
      font-weight: 600;
      font-size: rem(18px);
      line-height: rem(28px);
      letter-spacing: 0.005em;
      color: var(--primary-side-menu-color);
    }

    &--subtitle {
      font-family: $default-font;
      font-weight: 500;
      font-size: rem(15px);
      line-height: rem(22px);
      color: var(--primary-text-color);
    }

    &--wrapper {
      column-count: 2;
      column-gap: rem(64px);

      @include mq($until: tablet) {
        column-count: 1;
      }
    }

    &--data {
      font-family: $second-font;
      font-weight: 400;
      font-size: rem(14px);
      line-height: rem(24px);
      color: var(--primary-text-color);
      span:not(:last-child) {
        margin-right: rem(5px);
      }

      a {
        color: var(--active-side-menu-background-color);
      }
    }

    .postal-office__box {
      background-color: var(--section-background-2);
      border-left: rem(3px) solid var(--active-side-menu-background-color);
    }
    .postal-office__card {
      padding: rem($space-xl-size $space-xxl-size);
      background: $white;
      border-radius: rem(8px);
    }
  }
}
