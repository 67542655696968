@use 'sass:math';

.form {
  &__title {
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
    letter-spacing: 0.005em;
    color: var(--black);
    &--color {
      color: var(--active-side-menu-background-color);
    }
    &.contact-title {
      font-weight: 700;
      font-size: rem(24px);
      line-height: rem(36px);
    }

    span {
      color: red;
    }
  }

  &__subtitle {
    margin-bottom: rem(8px);
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(14px);
    line-height: rem(27px);
    color: var(--active-side-menu-background-color);

    span {
      color: red;
    }
  }

  &__capture--box {
    @include flex;
  }

  &__capture {
    max-height: rem(95px);
    width: 100%;
    padding: rem(5px);
    background: var(--white);
    border: 1px solid var(--primary-border-color);
    border-radius: rem(4px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__button-text {
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.005em;
  }

  &__weight {
    height: 100%;
    width: 100%;
    position: relative;

    .weight-text {
      font-family: $default-font;
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(27px);
      color: var(--active-side-menu-background-color);

      position: absolute;
      left: rem(-5px);
      top: rem(45px);
    }
  }

  &__select {
    background-color: var(--white);
    border: 1px solid var(--footer-navigation-links);
    border-radius: rem(8px);

    input {
      font-weight: 400;
      color: var(--icon-stroke-color-11);
    }
  }

  &__capture {
    cursor: pointer;
    position: relative;

    &:hover .button {
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      box-shadow: 0px 3px 12px rgba(34, 45, 56, 0.07);
      border: 1px solid var(--active-side-menu-background-color);
    }

    .button {
      position: absolute;
      top: rem(math.div(-$space-xxl-size, 2));
      right: rem(math.div(-$space-xxl-size, 2));
      width: rem($space-xxl-size);
      height: rem($space-xxl-size);
    }
  }
}

.complaint-thx-modal {
  max-width: 700px;

  &__message {
    line-height: rem(35px);
    text-align: center;

    svg {
      color: #42d312;
      width: rem(100px);
      height: rem(100px);

      display: block;

      margin: rem(32px) auto;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
