.carousel {
  position: relative;
  height: rem(300px);

  @include mq($from: small-desktop) {
    margin: rem(-$box-shadow-margin);
    height: rem(410px);
  }

  &-components:not(:first-child) {
    margin-top: $space-lg-size;
  }

  &-banner,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-slide > div {
    @include flex-center;
  }

  .slick-next,
  .slick-prev {
    padding: 0;
  }

  .carousel-banner {
    @extend .container;
    color: var(--white);

    &__link {
      display: block;
      position: relative;
      height: 100%;
    }
    &__bg-image {
      position: absolute;
      z-index: -1;

      @include mq($from: small-desktop) {
        border-radius: rem(12px);
      }
    }

    &__bg-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $background-gradient-1;
      z-index: -1;

      @include mq($from: small-desktop) {
        border-radius: rem(12px);
      }
    }

    // OLD_VERSION
    // &__background {
    //   @include background-size-cover;
    //   background-position: 100% !important;
    //   height: 100%;
    //   border-radius: 0;
    //   overflow: hidden;

    //   @include mq($from: small-desktop) {
    //     border-radius: rem(12px);
    //   }
    // }

    &__box {
      padding: rem(79px 70px);
      text-align: center;

      @include mq($until: small-tablet) {
        padding: rem(50px $space-xs-size);
      }

      @include mq($from: small-desktop) {
        padding-left: rem(60px);
        padding-top: rem(51px);
        padding-bottom: rem(52px);
        text-align: start;
      }
    }

    &__link:hover {
      .carousel-banner__title {
        text-decoration: underline;
      }
    }

    &__title {
      margin: 0;
      margin-bottom: rem(14px);
      font-size: rem(24px);
      line-height: rem(36px);
      font-weight: 700;

      @include mq($from: small-desktop) {
        margin: 0;
        width: 80%;
        font-size: rem(40px);
        line-height: rem(50px);
      }
    }

    &__description {
      margin: 0 auto;
      width: rem(340px);
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(24px);

      @include mq($until: small-tablet) {
        width: rem(240px);
      }

      @include mq($from: small-desktop) {
        width: 70%;
        margin: 0;
        margin-top: rem(14px);
        margin-bottom: rem($box-shadow-margin);
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(31px);
      }
    }

    @include mq($until: small-desktop) {
      padding: 0;
    }

    @include mq($until: wide) {
      max-width: 100vw;
    }
  }
  //Atteintion
  .carousel-attentions {
    @extend .container;
    color: var(--secondary-text-color);

    @include mq($until: wide) {
      max-width: 100vw;
    }

    &__box {
      height: rem(285px);
      padding: rem(40px 50px);
      background-color: var(--card-background-5);
      box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
      border-radius: rem(12px);
      position: relative;
    }

    &__title {
      margin: 0;
      font-family: $default-font;
      font-weight: 600;
      font-size: rem(18px);
      line-height: rem(28px);

      @include mq($from: tablet) {
        font-weight: 700;
        font-size: rem(24px);
        line-height: rem(36px);
      }
    }

    &__description {
      max-height: rem(120px);
      margin: rem($space-xs-size 0 $space-md-size 0);
      font-size: rem($default-font-size);
      line-height: rem(21px);
      font-weight: 400;
      color: var(--primary-text-color);

      // text overflow dots
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include mq($from: tablet) {
        max-height: rem(90px);
        margin: rem($space-xs-size 0 $space-lg-size 0);
        font-size: rem(18px);
        line-height: rem(31px);
        -webkit-line-clamp: 3;
      }
    }

    &__href {
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(24px);
      color: var(--primary-side-menu-color);
    }

    &__icon {
      display: none;

      @include mq($from: tablet) {
        display: block;
        font-size: rem(250px);
        position: absolute;
        top: rem(35px);
        right: 0;
      }
    }
  }

  @include mq($from: small-desktop) {
    .slick-slide {
      padding: rem($box-shadow-margin $box-shadow-margin 0);
    }
  }
}

.banner.slick-slider {
  position: relative;

  .slick-arrow {
    display: none;

    path {
      stroke: $icon-stroke-color-1;
    }

    @include mq($from: small-desktop) {
      display: block;
      width: rem(34px);
      height: rem(34px);
      font-size: rem(22px);
      background-color: var(--white);
      border-radius: 50%;
    }
  }

  .slick-next {
    position: absolute;
    top: unset;
    bottom: rem(52px);
    left: rem(335px);
    z-index: 4;
  }

  .slick-prev {
    position: absolute;
    top: unset;
    bottom: rem(52px);
    left: rem(90px);
    z-index: 4;
  }

  .slick-next:before,
  .slick-prev:before {
    content: '';
  }

  .slick-arrow:hover {
    background-color: $icon-background-color-1;
    fill: var(--footer-navigation-title);

    path {
      stroke: var(--white);
    }
  }

  .slick-dots {
    height: rem(32px);
    width: auto;
    display: flex !important;
    align-items: center;
    position: absolute;
    bottom: rem(52px);
    text-align: left;
    transform: translate3d(rem(139px), -50%, 0);

    li {
      @include flex;
      align-items: center;

      button {
        height: rem($space-xs-size);
        width: rem($space-xs-size);
        background-color: var(--footer-navigation-title);
        border-radius: 50%;

        &:before {
          content: '';
        }
      }

      &.slick-active button {
        background-color: var(--white);
      }
    }

    @include mq($until: small-desktop) {
      position: absolute;
      transform: unset;
      margin: unset;
      text-align: center;
      padding-bottom: unset;
      left: 50%;
      width: fit-content;
      bottom: rem($space-lg-size * 2);
      transform: translate3d(-50%, 0, 0);
    }
  }

  @include mq($from: small-desktop) {
    .slick-list {
      position: absolute;
      top: 0;
      margin: 0 -50%;
      width: 200%;
      height: 100%;
    }
  }
}

.components-links.slick-slider {
  position: relative;
  height: auto;

  .slick-list {
    margin: 0 -50%;
    width: 200%;
  }

  .slick-arrow {
    display: block;
    width: rem($space-lg-size);
    height: rem(56px);

    @include mq($until: 816px) {
      display: none;
    }
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    padding: rem(0 $space-md-size);

    svg {
      width: rem($space-lg-size);
      height: rem(56px);

      path {
        fill: none;
        stroke: var(--white);
      }
    }
  }

  .slick-next {
    right: rem($box-shadow-margin);
  }

  .slick-prev {
    left: rem($box-shadow-margin);
  }

  .button.slick-arrow:before {
    content: '';
  }

  .slick-dots {
    bottom: rem(-$space-xl-size);

    li {
      &.slick-active {
        button {
          background-color: var(--white);
        }
      }

      button {
        height: rem($space-xs-size);
        width: rem($space-xs-size);
        background-color: var(--footer-navigation-title);
        border-radius: 50%;

        &:before {
          content: '';
        }
      }
    }
  }

  .carousel-components {
    @extend .container;
  }

  @include mq($from: small-desktop) {
    .slick-slide:last-child {
      padding-bottom: rem($box-shadow-margin);
    }
  }
}

.gallery-images.slick-slider {
  position: relative;
  height: rem(600px);

  .slick-arrow {
    display: block;
    width: rem(52px);
    height: rem(56px);
    z-index: 4;

    svg {
      width: rem($space-lg-size);
      height: rem(56px);

      path {
        stroke: var(--active-side-menu-border-color);
      }
    }

    &:hover svg path {
      stroke: var(--active-side-menu-background-color);
    }
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    padding: rem(0 $space-md-size);
  }

  .slick-next {
    right: rem($box-shadow-margin);
  }

  .slick-prev {
    left: rem($box-shadow-margin);
  }

  .button.slick-arrow:before {
    content: '';
  }

  .carousel-components {
    @extend .container;
  }

  @include mq($from: small-desktop) {
    .slick-slide:last-child {
      padding-bottom: rem($box-shadow-margin);
    }
  }
}

.components-news.slick-slider {
  position: relative;
  height: auto;

  .slick-list {
    padding-bottom: $space-xxl-size;
    margin: 0 -50%;
    width: 200%;
  }

  .slick-arrow {
    width: rem(34px);
    height: rem(34px);
    font-size: rem(22px);
    background-color: var(--white);
    border-radius: 50%;

    path {
      stroke: $icon-stroke-color-1;
    }
  }

  .slick-next {
    position: absolute;
    top: rem(-$space-xxl-size);
    right: rem($box-shadow-margin);

    path {
      stroke: $icon-stroke-color-1;
    }
  }

  .slick-prev {
    position: absolute;
    top: rem(-$space-xxl-size);
    right: rem(#{calc(34px + 30px + $space-md-size)});
    left: auto;

    path {
      stroke: $icon-stroke-color-1;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    content: '';
  }

  .slick-arrow:hover {
    background-color: $icon-background-color-1;

    svg path {
      fill: none;
      stroke: var(--white);
    }
  }

  .carousel-components {
    @extend .container;
  }
}
//Attentions
.attentions.slick-slider {
  height: rem(350px);

  @include mq($until: small-desktop) {
    height: rem(300px);
  }

  .slick-dots {
    li {
      button {
        height: rem($space-xs-size);
        width: rem($space-xs-size);
        background-color: var(--third-border-color);
        border-radius: 50%;

        &:before {
          content: '';
        }
      }

      &.slick-active button {
        background-color: var(--secondary-text-color);
      }
    }
  }

  @include mq($from: small-desktop) {
    .slick-list {
      position: absolute;
      top: 0;
      margin: 0 -50%;
      width: 200%;
      height: 100%;
    }
  }
}
