.filters__select--map {
  .filters__option {
    padding: 5px 10px;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    &--selected {
      background-color: var(--icon-stroke-color-6);
      color: var(--white);
    }
    &--active:not(&--selected) {
      background: rgba(0, 116, 217, 0.1);
    }
    &:hover:not(&--selected) {
      background: rgba(0, 116, 217, 0.1);
    }
  }
}
