@for $i from $space-from through $space-to {
  .m-#{$i} {
    margin: rem(#{$i}px) !important;
  }

  .mt-#{$i} {
    margin-top: rem(#{$i}px) !important;
  }

  .mb-#{$i} {
    margin-bottom: rem(#{$i}px) !important;
  }

  .ml-#{$i} {
    margin-left: rem(#{$i}px) !important;
  }

  .mr-#{$i} {
    margin-right: rem(#{$i}px) !important;
  }

  .mx-#{$i} {
    margin-left: rem(#{$i}px) !important;
    margin-right: rem(#{$i}px) !important;
  }

  .my-#{$i} {
    margin-top: rem(#{$i}px) !important;
    margin-bottom: rem(#{$i}px) !important;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
