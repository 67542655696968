.gallery {
  position: relative;
  padding-bottom: calc(#{rem(150px)} + #{rem($box-shadow-margin)});

  &.hidden-gallery {
    padding-bottom: 0;
  }

  &-images.carousel {
    margin: 0;
  }

  .gallery__image {
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-color: var(--section-background-2);
    height: 100%;
    width: 100%;
    border-radius: 0;
    overflow: hidden;
  }

  .gallery-dots {
    @include background-size-cover;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
  }

  .slick-dots.slick-thumb {
    @extend .container;
    display: flex !important;
    align-items: flex-end;
    position: absolute;
    bottom: rem(-155px);
    height: rem(155px);
    padding: rem(0 1px);
    overflow: auto hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      height: rem(10px);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset rem(0px 3px 7px) rgba(0, 0, 0, 0.09);
      border: 1px solid var(--footer-navigation-links);
    }

    &::-webkit-scrollbar-thumb {
      height: rem(8px);
      background-color: var(--footer-navigation-links);
      border: 1px solid var(--primary-side-menu-color);
      border-radius: rem(1px);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--active-side-menu-background-color);
      border-color: var(--footer-navigation-links);
    }

    li {
      min-width: rem(110px);
      height: rem(150px - 10px);

      &.slick-active .gallery-dots {
        border: 3px solid $active-side-menu-background-color;
      }
    }
  }

  .carousel-components {
    @include flex-center;
  }

  @include mq($from: small-desktop) {
    .slick-list {
      position: absolute;
      z-index: -1;
      top: 0;
      margin: 0 -50%;
      width: 200%;
      height: 100%;
      overflow: visible;
    }
  }
}
