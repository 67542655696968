html,
body {
  padding: $body-padding;
  margin: $body-margin;
  font-family: $second-font;
  background-color: var(--section-background-2);
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: $default-font;
}

section {
  position: relative;
}

img {
  display: block;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.error {
  color: $primary-error-color;
}

.field-error {
  color: $primary-error-color;
  font-size: rem($default-font-size);
  line-height: rem(18px);
  font-weight: bold;
}

.info-message {
  font-weight: normal;
}

input::placeholder,
textarea::placeholder {
  color: $input-placeholder;
  font-size: rem(16px);
  font-weight: 500;
}

.text-align-justify {
  text-align: justify;
}
