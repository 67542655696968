.search-box {
  @include flex-center-vert;
  position: relative;
  height: 0%;
  width: 0%;
  overflow: hidden;
  margin: rem(0 $space-md-size);
  background-color: var(--white);
  right: 0;
  z-index: 44;
  transition: width $transition-time;

  &:not([class*='track-trace']) {
    @include mq($until: small-desktop) {
      @include flex-center;
      position: fixed;
      bottom: 0;
      left: 0;
      opacity: 0;
      margin: 0;
      padding: 0;
      background-color: unset;
      transition: opacity $transition-time;

      .search-box--mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $icon-background-color-1;
      }

      .search-box__container {
        width: 100%;
        background-color: var(--white);
        padding: rem($space-xl-size);
        border-radius: rem(8px);
        margin: rem($space-lg-size);
        transform: scale(0);
        transition: transform $transition-time;
      }

      .input-icon {
        margin: rem(0 $space-xl-size);
      }

      &.search-box--visible .search-box__container {
        transform: scale(1);
        transition: transform $transition-time;
      }
    }
  }

  &.search-box--visible {
    width: 100%;
    max-width: rem(565px);
    height: rem(60px);
    padding: rem(0 $space-xs-size);

    @include mq($until: small-desktop) {
      max-width: 100%;
      height: calc(100vh - #{rem(61px)});
      opacity: 1;
    }
  }

  &__container {
    display: flex;
    max-width: rem(585px);
    width: 100%;
    position: relative;

    .input__container {
      width: 100%;

      input {
        max-width: rem(509px);
        width: 100%;
        height: rem(40px);
        border: rem(1px) solid var(--primary-border-color);
        border-radius: rem(6px);
        background-color: var(--white);
        padding: rem($space-xs-size) rem($space-xs-size) rem($space-xs-size) rem(41px);
        font-family: $default-font;
        font-size: rem(14px);
        line-height: rem(21px);
        color: var(--text-color-2);
        font-weight: 500;
      }

      input::placeholder {
        font-size: rem(14px);
        line-height: rem(21px);
        color: var(--footer-navigation-title);
        font-weight: 500;
      }
    }

    .input-icon {
      @include flex-center;
      position: absolute;
      top: 50%;
      left: rem(11px);
      transform: translate3d(0, -50%, 0);

      svg {
        fill: none;
        width: rem(18px);
        height: rem(18px);
      }

      + .input__container input {
        padding-left: rem($space-lg-size * 2);
      }
    }

    .search-button {
      @include flex-center;
      margin-left: rem($space-md-size);
      padding: rem(11px);
      height: rem(40px);
      color: var(--white);
      font-size: rem(18px);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        var(--active-side-menu-background-color);
      box-shadow: rem(0) rem(3px) rem(7px) rgba(0, 0, 0, 0.09);
      border-radius: rem(6px);
      cursor: pointer;

      svg {
        fill: none;
      }
    }

    .search-button:hover {
      background-color: var(--icon-stroke-color-3);
    }
  }
}

@include mq($until: small-desktop) {
  .header-news + .header-container .search-box.search-box--visible {
    height: calc(100vh - #{rem(61px + 46px)});
  }
}
