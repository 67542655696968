.slider-card {
  @include flex-column;
  justify-content: space-between;
  height: 100%;
  padding: rem($space-lg-size);
  background-color: var(--white);
  box-shadow: rem(0) rem(12px) rem(19px) rgba(0, 0, 0, 0.09);
  border-radius: rem(6px);
  border: 1px solid var(--primary-border-color);
  transition: transform $transition-time, box-shadow $transition-time;

  &:hover {
    transform: scale(1.03);
    box-shadow: none;
  }

  &__box {
    @include flex-center;
  }
  &__img-block {
    position: relative;

    background-size: cover;
    background-color: $primary-border-color;

    margin-right: rem($space-sm-size);
    width: rem(80px);
    height: rem(80px);

    border-radius: 100%;
    * {
      border-radius: inherit;
    }
  }

  &__text-area {
    margin: 0;
    width: 100%;
    color: var(--primary-text-color);
    font-family: $second-font;
    font-weight: 400;
    font-size: rem($default-font-size);
    line-height: rem(21px);
    overflow: hidden;

    @include mq($from: desktop) {
      max-width: rem(240px);
    }
  }
  &__button.box-news {
    margin-top: rem($space-xs-size);

    .box-news__subtitle {
      margin: 0;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(24px);
      color: var(--primary-side-menu-color);
    }
  }
}
