.slider-box {
  @include flex-center;

  &__item {
    display: block;
    width: rem(230px);
    height: rem(177px);
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
    border-radius: rem(12px);
    padding: rem(16px);
    background-color: var(--white);
    transition: transform $transition-time;

    @include mq($until: 1269px) {
      height: rem(150px);
      width: rem(200px);
    }

    @include mq($until: desktop) {
      height: rem(130px);
      width: rem(150px);
      padding: rem($space-xs-size);
    }

    @include mq($until: 816px) {
      height: rem(130px);
      width: rem(130px);
      padding: rem(5px);
    }
  }

  &__item:not(:last-child) {
    margin-right: rem($space-lg-size);

    @include mq($until: desktop) {
      margin-right: rem($space-xs-size);
    }
  }

  &__item-image {
    position: relative;
    height: rem(83px);
    width: rem(83px);
    margin-bottom: rem($space-xxl-size);

    border-radius: 100%;

    * {
      border-radius: inherit;
    }

    @include mq($until: 1269px) {
      margin-bottom: rem($space-lg-size);
      width: rem(68px);
      height: rem(68px);
    }

    @include mq($until: desktop) {
      margin-bottom: rem($space-xs-size);
      width: rem(55px);
      height: rem(55px);
    }
  }

  &__subtitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: rem(17px);
    line-height: rem(27px);
    font-weight: 700;
    color: var(--secondary-text-color);
  }

  &__item:hover {
    transform: scale(1.03);

    .slider-box__background {
      box-shadow: rem(0) rem(12px) rem(19px) rgba(0, 82, 156, 0.2);
    }
  }
}
