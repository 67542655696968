.upload {
  color: var(--secondary-text-color);

  &__container {
    width: 100%;
    height: rem(57px);
    @include flex-center-vert;
    margin-bottom: rem($space-md-size);
    padding: rem($space-lg-size);
    background-color: var(--white);
    border: 1px solid var(--footer-navigation-links);
    border-radius: rem(8px);
  }

  &__file {
    @include flex;
    width: 100%;

    &__name {
      width: rem(240px);
      margin: rem(0 $space-xs-size);
      font-family: $default-font;
      font-weight: 500;
      font-size: rem(14px);
      line-height: rem(21px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include mq($until: small-tablet) {
        display: none;
      }

      @include mq($from: small-desktop) {
        width: rem(380px);
      }
    }
  }

  &__status {
    @include flex-center-vert;
    font-size: rem(12px);
    font-weight: 500;
    min-width: rem(150px);
  }

  &__progress {
    @include flex-center-vert;
    width: 100%;

    &__bar {
      background-color: var(--icon-stroke-color-6);
      flex-grow: 0;
      height: rem(4px);
      border-radius: rem(4px);
    }
    &__text {
      margin: rem(0 7px);
      font-family: $second-font;
      font-weight: 600;
      font-size: rem(12px);
      line-height: rem(16px);
      color: var(--active-breadcrumbs-color);
    }
  }

  &__file__remove {
    @include flex-center-vert;

    svg {
      font-size: rem($space-md-size);
    }

    &-text {
      margin-right: rem(8px);
      font-family: $default-font;
      font-weight: 500;
      font-size: rem(14px);
      line-height: rem(21px);
      color: var(--primary-side-menu-color);
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    @include flex;
    color: var(--primary-text-color);
  }

  &__error {
    font-family: $second-font;
    font-weight: 600;
    font-size: rem($default-font-size);
    line-height: rem(18px);
    color: $icon-stroke-color-10;
  }
}
