.postal-footer-info {
  @include flex;
  flex-wrap: wrap;
  max-width: calc(100% - #{rem($space-lg-size * 2)});
  padding: rem($space-md-size);
  background-color: var(--white);
  border: rem(1px) solid var(--primary-border-color);
  border-radius: rem(6px);
  box-shadow: rem(0 7px 15px) rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: rem($space-xs-size);
  left: rem($space-lg-size);
  gap: rem(8px);

  &__item {
    all: unset;
    color: var(--secondary-text-color);
    font-size: rem($default-font-size);
    line-height: rem(21px);
    display: flex;
    align-items: center;
    padding: rem(8px 20px);
    border-radius: rem(4px);
    cursor: pointer;
    transition: 0.1s;
    font-weight: bold;
    color: rgb(50, 50, 50);

    overflow: hidden;
    position: relative;

    &:where(:hover) {
      background-color: rgba(0, 0, 0, 0.07);
    }

    > * {
      z-index: 1;
    }

    .green {
      background-color: var(--point-green);
    }

    .red {
      background-color: var(--point-red);
    }

    .light-green {
      background-color: var(--point-light-yellow);
    }

    .orange {
      background-color: var(--point-orange);
    }

    .grey {
      background-color: var(--point-grey);
    }

    .blue {
      background-color: var(--point-blue);
    }

    .yellow {
      background-color: var(--point-yellow);
    }

    .point {
      position: absolute;
      left: rem(12px);
      top: 50%;
      width: rem(8px);
      height: rem(8px);
      border-radius: 100px;
      transition: 0.2s;
      transform: translate(-50%, -50%);

      &--active {
        width: 200%;
        height: 200%;
      }
    }
  }
}
