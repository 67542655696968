.news-banner {
  @include mq($from: small-desktop) {
    flex-direction: row;
  }

  .news-banner__image {
    @include background-size-cover;
    height: fit-content;
    overflow: hidden;
    border-radius: rem(9px);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);
    display: none;

    span {
      position: static !important;
      img {
        position: static !important;
        width: unset !important;
        height: unset !important;
      }
    }

    @include mq($from: small-desktop) {
      border-top-left-radius: 0;
      border-bottom-right-radius: rem(9px);
      display: block;
    }
  }

  .news-banner__text {
    height: 100%;
  }

  .news-banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: rem(25px 30px);
    background-color: var(--white);
    border: 1px solid var(--primary-border-color);
    border-radius: rem(9px);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);

    height: 100%;

    @include mq($from: desktop) {
      border-top-left-radius: rem(9px);
      border-bottom-right-radius: 0;
    }

    &__title {
      margin: 0;
      margin-bottom: rem($space-xs-size);
      font-weight: 600;
      font-size: rem(24px);
      line-height: rem(38px);
      color: var(--active-side-menu-background-color);
    }

    &__text-wrapper {
      position: relative;
      flex: 1;
      min-height: rem(65px);
    }

    &__text-area {
      position: static;
      top: 0;
      left: 0;
      height: 100%;
      margin: 0;
      margin-bottom: rem($space-sm-size);
      height: 100%;
      width: 100%;
      color: var(--primary-text-color);
      font-family: $second-font;
      font-weight: 400;
      font-size: rem(18px);
      line-height: rem(31px);
      overflow: hidden;
      max-height: rem(200px);

      @include mq($from: small-desktop) {
        max-width: 100%;
        max-height: fit-content;
        position: absolute;
      }
    }

    &__shadow {
      background: linear-gradient(180deg, transparent, 28%, white, 105%, white);
      position: absolute;
      width: 100%;

      bottom: 0;
      height: 50px;
      left: 0;
    }

    .card-header__box {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}
