.navigation-settings {
  padding: rem(30px 0);
  padding-top: rem(15px);
  margin-top: rem(15px);
  font-family: $default-font;

  @include mq($from: small-desktop) {
    justify-content: space-between;
    padding: rem(60px 0);
    padding-top: rem(30px);
    margin-top: rem(30px);
  }

  &__item {
    .item__link {
      @include mq($until: small-desktop) {
        @include flex-center-between;
        padding: rem($space-lg-size);
        background-color: var(--white);
        border: rem(1px) solid var(--primary-border-color);
        box-shadow: rem(0 7px 15px) rgba(0, 0, 0, 0.07);
        border-radius: rem(12px);

        &:not(:last-child) {
          margin-bottom: rem($space-lg-size);
        }
      }
    }

    .item-title {
      color: var(--secondary-text-color);
      font-weight: 600;
      font-size: rem(22px);
      line-height: rem(36px);

      @include mq($from: small-desktop) {
        color: var(--white);
        font-size: rem($space-lg-size);
        line-height: rem(32px);
        font-weight: 500;
        position: absolute;
        bottom: rem($space-md-size);
        left: rem($space-lg-size);
      }
    }

    .item__box {
      @include mq($until: small-desktop) {
        min-width: rem(70px);
        height: rem(70px);
        @include flex-center;
        background-color: var(--section-background-2);
        font-size: rem(50px);
        border-radius: 50%;
      }

      display: block;
      @include background-size-cover;
      height: rem(220px);
      border-radius: rem(12px);
      overflow: hidden;
      position: relative;
      transition: transform $transition-time, box-shadow $transition-time;

      .background-gradient {
        height: 100%;
        background: $background-gradient-2;
      }

      &:hover,
      &:focus {
        transform: scale(1.05);
        box-shadow: 0px 0px 29px 2px rgba(0, 0, 0, 0.3);
        .background-gradient {
          background: none;
        }
      }
    }
  }
}
