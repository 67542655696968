.mailing-description {
  &__box-subtitle {
    padding-left: rem(30px);
  }
  &__box {
    padding-left: rem(30px);
    width: 100%;
    border-left: 2px solid var(--footer-navigation-links);
    position: relative;
  }

  &__title {
    margin: rem(4px 0);
    font-family: $default-font;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
    letter-spacing: 0.005em;
    color: var(--primary-text-color);
  }

  .delivered {
    color: var(--active-side-menu-background-color);
  }

  &__subtitle {
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(24px);
    color: var(--primary-text-color);
  }

  &__point {
    width: rem(16px);
    height: rem(16px);
    background-color: var(--white);
    border-radius: 50%;
    border: 4px solid var(--footer-navigation-links);
    position: absolute;
    top: rem(-10px);
    left: rem(-10px);
  }
  .delivered-point {
    border: 4px solid var(--active-side-menu-background-color);
  }
}

.mailing-footer {
  margin-top: rem($space-lg-size);
  &__text {
    font-weight: 400;
    font-size: rem($default-font-size);
    line-height: rem(23px);
    color: var(--active-breadcrumbs-color);
  }
  &__text-span {
    color: var(--primary-text-color);
  }
}
.mailing-parcel {
  padding: rem(14px $space-lg-size);
  @include flex;
  flex-wrap: wrap;

  &__box {
    height: 100%;
    @include flex-center-vert;
    padding: rem(3px 7px);
    background-color: var(--primary-border-color);
    border-radius: rem(4px);

    cursor: pointer;
  }
  &__box:not(:last-child) {
    margin-right: rem(14px);
    margin-bottom: rem(14px);
  }
  &__icon {
    @include flex;
    font-size: rem(12px);
    margin-right: rem(5px);
  }
  &__number {
    font-family: $second-font;
    font-weight: 600;
    font-size: rem(12px);
    line-height: rem(21px);

    color: var(--secondary-text-color);
  }
}
.mailing-parcel__active {
  background-color: var(--card-background-5);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);
}
