.cart {
  font-family: $default-font;
  .color-gray {
    color: var(--active-breadcrumbs-color);
  }
  .color-dark-gray {
    color: var(--primary-text-color);
  }

  &-action-button {
    background-color: var(--white);
    border-color: var(--footer-navigation-links);
  }

  &__items,
  &__info {
    border-radius: rem(8px);
    background-color: var(--white);
    padding: rem(24px);
  }

  &__item {
    padding: rem(12px);
    border-radius: rem(8px);
    border: 1px solid var(--primary-border-color);
    margin-bottom: rem(24px);
    &:last-child {
      margin-bottom: 0;
    }
    &__header {
      &__info {
        display: flex;
        align-items: center;
        gap: rem(16px);
        @media (max-width: 420px) {
          flex-direction: column;
        }
      }
      &__image {
        padding: rem(8px);
        border-radius: rem(8px);
        background-color: var(--section-background-2);
        height: rem(100px);
        width: rem(140px);
        flex: 0 0 auto;
      }
      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: rem(20px);
        height: 100%;
        &__count {
          input {
            width: rem(100px);
          }
        }
        &__price {
          font-weight: 600;
          color: var(--active-side-menu-background-color);
        }
      }
      &__months {
        display: flex;
        flex-wrap: wrap;
        gap: 0.4rem;
        &__item {
          font-size: 0.92rem;
          padding: 0.125em 0.5em;
          border-radius: rem(8px);
          background-color: var(--primary-border-color);
          color: var(--active-breadcrumbs-color);
          &--initial {
            background-color: var(--third-border-color);
          }
        }
      }
    }

    &__body {
      border-top: 1px solid var(--primary-border-color);
      &__label {
        margin-bottom: rem(8px);
      }
    }
  }

  &__info {
    &__content {
      &__list {
        list-style: none;
        padding: 0;
        li {
          display: flex;
          gap: rem(16px);
          justify-content: space-between;
          padding-bottom: rem(8px);
          &:last-child {
            padding-bottom: 0;
          }
        }
        &__price {
          flex: 0 0 auto;
        }
      }
    }

    &__footer {
      &__total {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: rem(20px);
        color: var(--text-color-1);
        margin-bottom: rem(18px);
      }
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    &__badge {
      position: relative;
    }
    &__count {
      position: absolute;
      bottom: rem(-8px);
      left: rem(-12px);
      font-size: rem(16px);
      padding: rem(4px);
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      border-radius: rem(20px);
      min-width: rem(26px);
      text-align: center;
    }
  }
}
