.modal {
  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    z-index: 99;
    background-color: #01020282;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  min-width: rem(320px);
  max-width: rem(380px);
  min-height: rem(300px);
  background-color: var(--white);
  border-radius: rem(6px);
  padding: rem(24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  & > * {
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: flex-end;
    .button {
      padding: 0;
      width: fit-content;
      height: fit-content;
      color: var(--footer-navigation-title);
    }
  }
  &__body {
    //
  }
  &__footer {
    & > * {
      margin-bottom: rem(12px);
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
