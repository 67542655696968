.breadcrumbs {
  @include flex-center-vert;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item:not(:last-child) {
    margin-right: rem(8px);
  }

  &__text {
    font-family: $default-font;
    font-weight: 500;
    font-size: rem(12px);
    line-height: rem(14px);
    color: var(--primary-breadcrumbs-color);
  }

  .breadcrumbs__text--active {
    font-weight: 400;
    color: var(--active-breadcrumbs-color);
  }
}
