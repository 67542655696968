.product-characteristics {
  font-family: $default-font;
  .color-gray {
    color: var(--active-breadcrumbs-color);
  }
  .color-dark-gray {
    color: var(--primary-text-color);
  }

  &__content {
    & > * {
      border-bottom: 1px solid var(--primary-border-color);
      padding: rem(8px 0);
    }
  }
}
