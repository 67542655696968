.pagination {
  font-family: $default-font;
  font-size: rem(14px);
  line-height: rem(24px);
  font-weight: 400;

  .pages-wrapper {
    @include flex-center-vert;

    > span {
      @include flex-center-vert;
      border: rem(1px) solid $fourth-border-color;
      color: var(--primary-side-menu-color);
      border-radius: rem(4px);
      padding: rem(3px 7px 3px 8px);
      margin-right: rem(5px);
      height: rem(30px);

      &.selected {
        color: $white;
        background-color: var(--active-side-menu-background-color);
      }
    }

    span {
      margin-right: rem(5px);
      cursor: pointer;
      border: rem(1px) solid $fourth-border-color;
      color: var(--primary-side-menu-color);
      border-radius: rem(4px);
      padding: rem(6px 7px 5px 8px);
    }

    .disabled {
      color: grey;
      cursor: not-allowed;
      border: none !important;
    }
  }
}
