.pm-tooltip {
  $self: &;

  &--inline {
    display: inline-block;
    #{$self}__trigger {
      display: inline-block;
    }
  }

  &--nowrap {
    #{$self}__body,
    #{$self}__body-title {
      white-space: nowrap;
    }
  }

  &__body {
    animation: $animation-base;
    transition: opacity $transition-time;

    .container {
      padding: 0;
    }
  }

  &__wrapper.pm-tooltip--overtop {
    z-index: 9999;
  }

  &__wrapper {
    border-radius: rem(4px);
    z-index: 9997;
    color: $primary-text-color;
  }

  &__body {
    font-size: rem($gutter-size);
  }

  &__arrow {
    font-size: rem($gutter-size);
    display: inline-block;
    height: 1em;
    width: 1em;
  }

  &__arrow path {
    fill: $white;
  }

  &__wrapper[data-popper-placement*='top'] &__arrow {
    bottom: rem(-$gutter-size + 5);
    svg {
      transform: translateY(-1px) rotate(180deg);
    }
  }

  &__wrapper[data-popper-placement*='right'] &__arrow {
    left: rem(-$gutter-size + 5);
    svg {
      transform: rotate(-90deg);
    }
  }

  &__wrapper[data-popper-placement*='bottom'] &__arrow {
    top: rem(-$gutter-size + 5);
    svg {
      transform: translateY(-1px) rotate(0deg);
    }
  }

  &__wrapper[data-popper-placement*='left'] &__arrow {
    right: rem(-$gutter-size + 5);
    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    transition: transform $transition-time;
  }
}
