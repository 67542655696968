.product-info {
  font-family: $default-font;
  .color-gray {
    color: var(--active-breadcrumbs-color);
  }
  .color-dark-gray {
    color: var(--primary-text-color);
  }
  & > * {
    margin-bottom: rem(8px);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    font-size: rem(18px);
  }
  &__price {
    font-size: rem(24px);
    font-weight: 600;
    color: var(--active-side-menu-background-color);
    &.discount {
      text-decoration: line-through;
      color: var(--footer-navigation-title);
      margin-right: rem(12px);
    }
  }
  &__size {
    & > * {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &__actions {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .product-actions {
      &__quantity {
        width: 100%;
      }
    }
    & > * {
      width: fit-content;
      &:last-child {
        flex: 4;
        min-width: rem(100px);
        min-height: rem(46px);
      }
    }
  }
}
