.filter-search {
  position: relative;
  padding: rem(6px) rem($space-md-size + 24px) rem(6px) 0;
  background-color: $white;
  border-radius: rem(8px);
  margin-bottom: rem(30px);
  // font-family: $default-font;
  &__icon {
    position: absolute;
    margin: auto;
    right: 0;
    top: 0;
    bottom: 0;
    height: fit-content;
    color: var(--icon-stroke-color-3);
    margin-right: rem($space-md-size);
    svg {
      fill: none;
    }
  }

  input {
    border: none !important;
    box-shadow: none !important;
    padding-right: rem($space-md-size - 8px) !important;
  }
}
