.postal-sidebar {
  overflow: auto;
  width: 100%;
  height: calc(100vh - #{rem(130px)});
  background-color: var(--section-background-2);
  box-shadow: rem(0 12px 19px) rgba(0, 0, 0, 0.09);

  @include mq($until: small-desktop) {
    @include flex;
    height: rem(200px);

    .postal-sidebar__item {
      min-width: rem(250px);
    }
  }

  p {
    margin: rem(0);
  }

  h2 {
    margin: rem(0);
  }

  &__item {
    width: 100%;
    border-left: rem(3px) solid transparent;
    border-top: rem(1px) solid var(--primary-border-color);
    border-bottom: rem(1px) solid var(--primary-border-color);
    padding: rem(18px $space-lg-size);
    font-family: $default-font;
    transition: border $transition-time, background-color $transition-time;

    @include mq($until: small-desktop) {
      border-bottom: rem(3px) solid transparent;

      &:not(:first-child) {
        border-left: rem(1px) solid var(--primary-border-color);
      }
    }

    h2 {
      color: var(--primary-side-menu-color);
      font-weight: 600;
      font-size: rem(18px);
      line-height: rem(28px);
      margin-bottom: rem(10px);
    }

    p {
      color: var(--active-side-menu-background-color);
      font-weight: 600;
      font-size: rem($default-font-size);
      line-height: rem(18px);
      font-family: $second-font;
      margin-bottom: rem(10px);
    }

    .postal-sidebar__item--content {
      color: var(--primary-text-color);
      font-size: rem($default-font-size);
      line-height: rem(21px);
      font-weight: 400;
      font-family: $second-font;
      white-space: pre-wrap;
      span:first-child {
        margin-right: rem(2px);
      }
    }
  }

  &__item:hover {
    background-color: var(--white);

    @include mq($until: small-desktop) {
      border-bottom: rem(3px) solid var(--active-side-menu-background-color);
    }

    @include mq($from: small-desktop) {
      border-left: rem(3px) solid var(--active-side-menu-background-color);
    }
  }
}
