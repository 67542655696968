.product-actions {
  $width-button: rem(32px);
  font-family: $default-font;

  display: flex;
  gap: 1rem;

  &__quantity {
    position: relative;
    border-radius: rem(8px);
    overflow: hidden;
    input {
      padding-right: $width-button + rem($space-md-size);
    }
    &-buttons {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      width: $width-button;
      height: 100%;
      & > * {
        background-color: transparent;
        height: 50%;
        border: 1px solid var(--footer-navigation-links);
        cursor: pointer;
        &:hover {
          svg {
            color: var(--primary-side-menu-color);
          }
        }
        svg {
          color: var(--active-breadcrumbs-color);
          width: 8px;
          height: 6px;
        }
        &:first-child {
          border-bottom: none;
        }
      }
    }
  }

  &__cart {
    border-color: var(--footer-navigation-links);
    background-color: var(--white);
    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
    &.button--disabled {
      border: 1px solid var(--footer-navigation-links);
    }
  }

  &__info {
    position: relative;
    &-quantity {
      position: absolute;
      top: rem(-10px);
      right: rem(-12px);
      font-size: rem(16px);
      padding: rem(4px);
      color: var(--white);
      background-color: var(--primary-side-menu-color);
      border-radius: rem(20px);
      min-width: rem(26px);
      text-align: center;
    }
  }
}
