.search-page-input {
  font-family: $default-font;
  margin-bottom: rem(40px);

  .title {
    font-weight: 700;
    font-size: rem(36px);
    line-height: rem(48px);
    color: var(--secondary-text-color);
    margin-bottom: rem($default-font-size);
  }

  .input-box {
    display: flex;
    max-width: rem(1170px);
    width: 100%;
    background: var(--white);
    box-shadow: rem(0px 3px 7px) rgba(0, 0, 0, 0.09);
    border-radius: rem(9px);
    padding: rem(30px);

    align-items: center;
    justify-content: space-between;
    gap: rem(15px);

    @include mq($until: small-tablet) {
      flex-direction: column;
      align-items: stretch;
    }

    .input-wrapper {
      max-width: rem(870px);
      width: 100%;
      position: relative;
      margin-right: rem(15px);

      .search-icon {
        position: absolute;
        left: rem(18px);
        top: rem(19px);
        width: rem(19px);
        height: rem(19px);

        path {
          stroke: var(--secondary-text-color);
        }
      }

      .close-icon {
        position: absolute;
        right: rem(18px);
        top: rem(19px);
        width: rem(19px);
        height: rem(19px);
        cursor: pointer;

        path {
          stroke: var(--secondary-text-color);
        }
      }

      .input-field {
        font-family: $default-font;
        background: var(--white);
        border: rem(1px) solid $fourth-border-color;
        box-sizing: border-box;
        border-radius: rem(8px);
        width: 100%;
        height: rem(56px);
        padding: rem(17px 25px 15px 44px);
        outline: none;
        color: var(--secondary-text-color);
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(24px);
      }

      .input-field:focus {
        border: rem(1px) solid var(--secondary-border-color);
      }
    }

    .submit-search {
      font-family: $default-font;
      cursor: pointer;
      background-color: var(--active-side-menu-background-color);
      min-width: rem(220px);
      width: 100%;
      padding: rem(0px 25px);
      height: rem(56px);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        var(--active-side-menu-background-color);
      box-shadow: rem(0px 3px 7px) rgba(0, 0, 0, 0.09);
      border-radius: rem(6px);
      outline: none;
      border: none;
      color: var(--white);
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(24px);
      display: flex;
      align-items: center;

      svg {
        width: rem(19px);
        height: rem(19px);
        margin-right: rem(10px);

        path {
          stroke: #ffff;
        }
      }
    }

    .submit-search:hover {
      background-color: var(--footer-background-color);
    }
  }
}
