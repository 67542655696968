@use 'sass:math';

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

.layout__main {
  @include flex-column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.layout__body {
  flex: 1 1 100%;
}

// error-page
.not-found-page {
  color: #000;
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Fira Sans', Avenir,
    'Helvetica Neue', 'Lucida Grande', sans-serif;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .page-404,
  .page-505 {
    h1 {
      display: inline-block;
      border-right: rem(1px) solid rgba(0, 0, 0, 0.3);
      margin: 0;
      margin-right: rem(20px);
      padding: rem(10px 23px 10px 0);
      font-size: rem(24px);
      font-weight: 500;
      vertical-align: top;
    }

    .left {
      display: inline-block;
      text-align: left;
      line-height: rem(49px);
      height: rem(49px);
      vertical-align: middle;

      h2 {
        font-size: rem(14px);
        font-weight: normal;
        line-height: inherit;
        margin: 0;
        padding: 0;
      }
    }
  }
}

// separated
.separated-border__sm {
  height: rem(1px);
  width: 100%;
  box-shadow: inset 0px -1px 0px var(--primary-border-color);
}

.separated-border__md {
  border-top: 1px solid var(--third-border-color);
}

.separated-border__lg {
  height: rem(2px);
  width: 100%;
  box-shadow: inset 0px -2px 0px var(--footer-navigation-links);
}

.point {
  width: rem(2px);
  height: rem(2px);
  background-color: var(--third-border-color);
}

// header
.layout__header {
  position: relative;
  z-index: 3;

  @include mq($until: small-desktop) {
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--primary-border-color);
  }

  .header-news {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: rem(math.div($base-size, 2.5) 0);
    background-color: var(--section-background-3);
    animation: headerAlert $transition-time;
    transition: padding $transition-time;

    .container {
      position: relative;

      .close-news {
        position: absolute;
        top: 50%;
        right: rem(5px);
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }

    .add-points-end {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .content {
      display: flex;

      p {
        color: $secondary-text-color;
        font-family: $second-font;
      }

      span {
        margin-right: rem(5px);
        color: $secondary-text-color;
        font-family: $second-font;
        font-weight: 600;
      }

      .icon {
        margin-right: rem(12px);
        margin-left: rem(5px);

        svg {
          width: rem(17px);
          height: rem(17px);

          path {
            stroke: $secondary-text-color;
            fill: $secondary-text-color;
          }
        }
      }
    }
  }

  .header-container {
    font-family: $default-font;
    background-color: var(--white);

    &__upper-content {
      height: rem(61px);
      width: 100%;
      @include flex-center-between;
      position: relative;

      @include mq($from: small-desktop) {
        height: rem(85px);
      }

      .logo-box {
        display: block;
        height: rem(39px);
        max-width: rem(39px);
        width: 100%;

        @include mq($from: small-desktop) {
          height: rem(39px);
          max-width: rem(255px);
          width: 100%;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .header-container__call-action {
        @include flex-center-between;
        margin: rem(0 $space-lg-size);
        width: 100%;

        @include mq($from: small-desktop) {
          margin: 0;
          @include flex-center-end;
        }
      }

      .call-box {
        @include mq($from: small-desktop) {
          width: 0;
          visibility: hidden;
          opacity: 0;
          transition: 0s;

          &__opacity {
            width: unset;
            visibility: visible;
            opacity: 1;
            transition: 1.5s;
          }
        }

        &__link {
          @include flex-center-vert;
          white-space: nowrap;
          @include mq($until: small-desktop) {
            flex-direction: column;
          }
        }

        &__text {
          margin: 0;
          font-weight: 600;
          font-size: rem(11px);
          line-height: rem(19px);
          text-align: right;
          color: var(--active-side-menu-background-color);

          @include mq($from: small-desktop) {
            font-weight: 500;
            font-size: rem($space-lg-size);
            line-height: rem(24px);
            color: var(--icon-stroke-color-2);
            text-transform: uppercase;
          }
        }

        &__span {
          @include flex-center;
          font-weight: 600;
          color: var(--active-side-menu-background-color);

          span {
            color: var(--secondary-text-color);
          }

          @include mq($from: small-desktop) {
            margin-left: rem(7px);
            font-size: rem($space-lg-size);
            line-height: rem(24px);
            span {
              color: var(--text-color-1);
            }
          }
        }
      }

      .action-box {
        @include flex;

        @include mq($until: small-desktop) {
          @include flex-center;
        }

        &__search {
          cursor: pointer;
          font-size: rem(18px);
          text-align: center;
          color: var(--icon-stroke-color-3);
          margin-right: rem($space-md-size);
          transition: color $transition-time;

          @include mq($from: small-desktop) {
          }

          @include mq($until: small-desktop) {
            @include flex-center;
            width: rem(36px);
            height: rem(36px);
            color: var(--text-color-3);
            border: 1px solid var(--primary-border-color);
            border-radius: 50%;
            position: relative;
          }

          svg {
            fill: none;
          }

          &:hover {
            .action-box__text {
              color: var(--icon-stroke-color-2);
            }
          }
        }

        &__text {
          margin: 0;
          margin-top: rem(9px);
          color: var(--text-color-1);
          font-size: rem(12px);
          line-height: rem(16px);
          font-weight: 500;
          display: block;
          white-space: nowrap;
        }

        &__night {
          margin: 0;
          cursor: pointer;
          font-size: rem(18px);
          color: var(--icon-stroke-color-3);
          text-align: center;

          svg {
            fill: none;
          }
        }

        &__night:hover {
          .action-box__text {
            color: var(--icon-stroke-color-2);
          }
        }

        &__account {
          margin: 0;
          margin-left: rem($space-md-size);
          cursor: pointer;
          font-size: rem(18px);
          color: var(--icon-stroke-color-3);
          text-align: center;

          @include mq($until: small-desktop) {
            @include flex-center;
            width: rem(36px);
            height: rem(36px);
            color: var(--text-color-3);
            border: 1px solid var(--primary-border-color);
            border-radius: 50%;
            position: relative;
          }

          svg {
            fill: none;
          }
        }

        &__account:hover {
          .action-box__text {
            color: var(--icon-stroke-color-2);
          }
        }
      }
    }

    &__lower-content {
      background-color: var(--text-color-1);
      border-top: rem(1px) solid var(--secondary-border-color);
      border-left: rem(1px) solid var(--secondary-border-color);

      .navigation {
        min-height: rem(51px);
        color: var(--white);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        &__links {
          &,
          .fade-in {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
          }

          .active-nav-link {
            background-color: var(--white);
            color: var(--text-color-1);
          }

          li {
            @include flex-center-vert;
            min-height: rem(51px);
            font-weight: 500;
            font-size: rem(16px);
            line-height: rem(22px);
            white-space: nowrap;
            cursor: pointer;
            border: none;
            border-collapse: collapse;
            border-bottom: rem(1px) solid var(--secondary-border-color);
            border-right: rem(1px) solid var(--secondary-border-color);
            padding: rem(0 $space-sm-size);
            justify-content: center;
            flex: 1 1 auto;
          }
        }

        &__language-switcher {
          @include flex-center;
          margin-left: rem($space-md-size);

          .select {
            @include flex-center;
            height: 100%;
            font-size: rem(14px);
            line-height: rem(18px);
            cursor: pointer;
            flex-wrap: nowrap;

            .language {
              font-weight: 400;
              color: var(--footer-navigation-links);
              display: block;
              margin-right: rem(5px);
            }

            .current-language {
              @include flex-center;
              height: 100%;
              font-weight: 500;
              white-space: nowrap;
            }

            .pm-tooltip {
              &,
              &__trigger {
                height: 100%;
              }
            }

            svg {
              padding-top: rem(2px);
              margin-left: rem(7px);
            }
          }
        }
      }
    }
  }

  .header-container__icon-box {
    @include flex-center;
    width: rem(61px);
    height: rem(61px);
    border: none;
    border-left: 1px solid var(--primary-border-color);
    border-right: 1px solid var(--primary-border-color);
    margin-right: var(--bs-gutter-x, -0.75rem);
    border-radius: 0;
    background-color: var(--white);
    cursor: pointer;

    svg {
      width: rem($space-lg-size);
      height: rem($space-lg-size);
    }
  }

  .header-news + .header-container + div .navigation-mobile {
    top: rem(61px + 46px);

    &.is-visible + .navigation-mobile--mask {
      height: calc(100vh - #{rem(61px + 46px)});
    }
  }

  .navigation-mobile {
    width: calc(100% + var(--bs-gutter-x, 1.5rem));
    padding-bottom: rem(22px);
    text-align: center;
    position: absolute;
    margin: rem(1px) var(--bs-gutter-x, -0.75rem) 0;
    overflow: hidden;
    top: rem(61px);
    left: 0;
    background-color: var(--section-background-2);
    box-shadow: 0px 16px 50px 9px rgba(117, 117, 117, 0.2);
    z-index: 9;
    transform: rotateX(90deg);
    transform-origin: top;
    transition: transform $transition-time ease;

    &.is-visible {
      transform: rotateX(0deg);

      + .navigation-mobile--mask {
        position: absolute;
        height: calc(100vh - #{rem(61px)});
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        margin-top: rem(1px);
      }
    }

    &__list {
      @include flex-center-column;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        cursor: pointer;
        margin: rem(12px 0);
        padding: rem($space-xs-size $space-lg-size);
        font-weight: 600;
        font-size: rem(18px);
        line-height: rem(28px);
        border: 1px solid transparent;
        border-radius: rem(6px);

        color: var(--primary-side-menu-color);
        transition: background-color $transition-time, box-shadow $transition-time;
      }

      li:hover,
      li.active-nav-link {
        background-color: var(--card-background-6);
        border: 1px solid var(--primary-border-color);
        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
      }
    }

    &__switcher {
      width: 100%;
      padding-top: rem(58px);
      border-top: 1px solid var(--primary-border-color);

      .select {
        @include flex-center-column;
        width: 100%;
      }

      .language {
        margin: 0;
        margin-bottom: rem(40px);
        font-weight: 500;
        font-size: rem($space-lg-size);
        line-height: rem(24px);

        color: var(--footer-navigation-title);
      }
    }
  }
}

// footer
.layout__footer {
  position: relative;

  .footer-container {
    width: 100%;
    height: 100%;
    margin-top: rem(40px);
    font-family: $default-font;
    background-color: var(--footer-background-color);

    &__upper-content {
      color: var(--white);
      width: 100%;
      padding: rem(40px) rem(0);

      @include mq($until: small-desktop) {
        @include flex-center-column;
      }

      @include mq($from: small-desktop) {
        @include flex-space-between;
      }

      .left-block {
        width: 100%;
        text-align: center;
        @include mq($until: small-desktop) {
          box-shadow: inset 0px -1px 0px var(--primary-border-color);
        }
        @include mq($from: small-desktop) {
          max-width: rem(250px);
          text-align: start;
        }

        &__logotype {
          margin: 0 auto;
          margin-bottom: rem(40px);
          height: rem(39px);
          max-width: rem(255px);
          width: 100%;
          padding: rem(0 5px);
          // stroke: var(--footer-navigation-links);

          @include mq($from: small-desktop) {
            margin: 0;
            margin-bottom: rem(25px);
            height: rem(55px);
            max-width: rem(55px);
            background: none;
          }

          svg {
            width: 100%;
            height: 100%;
            color: var(--icon-stroke-color-12);
          }
        }

        &__title {
          margin: 0;
          margin-bottom: rem($space-md-size);
          font-weight: 500;
          font-size: rem($space-lg-size);
          line-height: rem(24px);
          color: var(--icon-stroke-color-12);

          @include mq($from: small-desktop) {
            line-height: rem(24px);
            font-size: rem(16px);
          }
        }

        &__subtitle {
          margin: 0;
          margin-bottom: rem(7px);
          font-size: rem(18px);
          line-height: rem(21px);
          font-weight: 500;
          color: var(--icon-stroke-color-12);
          @include mq($from: small-desktop) {
            line-height: rem(17px);
            font-size: rem(14px);
          }
        }

        &__text {
          margin: 0;
          font-family: $second-font;
          font-weight: 400;
          font-size: rem(18px);
          line-height: rem(24px);
          color: var(--footer-navigation-links);

          @include mq($from: small-desktop) {
            font-size: rem(14px);
            line-height: rem(24px);
          }
        }

        .lower-text {
          margin: 0;
          margin-top: rem(25px);
          @include mq($until: small-desktop) {
            margin-bottom: rem(45px);
          }
          @include mq($from: small-desktop) {
            width: rem(220px);
          }
        }
      }

      .center-block {
        @include mq($until: small-desktop) {
          max-width: rem(470px);
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: rem(60px);
        }

        &__title {
          margin: 0;
          margin-bottom: rem($space-md-size);
          font-size: rem($space-lg-size);
          line-height: rem(30px);
          font-weight: 500;
          color: var(--footer-navigation-title);

          @include mq($from: small-desktop) {
            font-size: rem(16px);
            line-height: rem(24px);
          }
        }

        &__list {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        &__item {
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(48px);
          color: var(--footer-navigation-links);
          cursor: pointer;

          @include mq($from: small-desktop) {
            font-size: rem(16px);
            line-height: rem(48px);
            font-size: rem($default-font-size);
            line-height: rem(27px);
          }
        }

        &__item:hover {
          color: var(--white);
          text-decoration: underline;
        }
      }

      .right-block {
        max-width: rem(200px);
        width: 100%;
      }

      .right-block__list {
        padding: 0;
        margin: 0;
        list-style: none;

        @include mq($until: small-desktop) {
          margin-top: rem($space-md-size);
        }

        .right-block__item {
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(48px);
          color: var(--footer-navigation-links);
          cursor: pointer;

          @include mq($from: small-desktop) {
            font-size: rem($default-font-size);
            line-height: rem(27px);
          }
        }

        .right-block__item:hover {
          color: var(--white);
          text-decoration: underline;

          @include mq($until: small-desktop) {
            color: var(--footer-navigation-links);
          }
        }
      }
    }

    &__lower-content {
      @include flex-center-between;
      padding: rem(40px) rem(0) rem(20px) rem(0);

      @include mq($until: small-desktop) {
        flex-direction: column;
      }

      .left-content {
        max-width: rem(350px);
        width: 100%;
        @include flex-center-between;

        @include mq($until: small-desktop) {
          justify-content: center;
        }

        &__text {
          margin: 0;
          font-weight: 400;
          font-size: rem(14px);
          line-height: rem(24px);
          color: var(--footer-navigation-links);

          @include mq($until: small-desktop) {
            padding-top: rem(60px);
            font-size: rem(18px);
          }
        }
      }

      .right-content {
        @include flex-center-vert;

        .logo-box {
          height: rem(47px);

          svg {
            width: rem(180px);
            height: rem(47px);
            stroke: var(--icon-stroke-color-11);
            color: var(--icon-stroke-color-11);
          }
        }
      }

      .space-point {
        @include mq($until: small-desktop) {
          display: none;
        }
        display: block;
        background-color: $icon-stroke-color-4;
        width: rem(2px);
        height: rem(2px);
        margin: rem(0 25px);
        border-radius: 50%;
      }

      .language-change {
        @include mq($until: small-desktop) {
          display: none;
        }
        @include flex-center-vert;
        cursor: pointer;

        &__text {
          margin: 0;
          margin-right: rem(8px);
          color: var(--footer-navigation-links);
          font-size: rem(14px);
          font-weight: 500;
          line-height: rem(18px);
        }

        &__icon {
          fill: var(--footer-navigation-title);
        }
      }
    }

    &__bottom-content {
      @include flex-center-column;
      padding-bottom: rem(40px);

      @include mq($from: small-desktop) {
        flex-direction: row;
        justify-content: start;
      }

      .bottom-content__image {
        @include flex-center;
        width: 100%;
        max-width: rem(300px);
        min-height: rem(80px);
        margin: 0;
        margin-bottom: rem($space-md-size);
        @include background-size-cover;

        @include mq($from: small-desktop) {
          max-width: 25%;
          margin: 0;
          margin-right: rem($space-md-size);
        }
      }
      .bottom-content__text-box {
        max-width: rem(300px);
        @include mq($from: small-desktop) {
          max-width: 75%;
        }
      }
      .bottom-content__text {
        margin: 0;
        font-family: $second-font;
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(24px);
        text-align: center;
        color: var(--footer-navigation-links);

        @include mq($from: small-desktop) {
          font-size: rem(14px);
          line-height: rem(24px);
          text-align: justify;
        }
      }
    }

    .social-icon__list {
      @include flex-center;
      padding: 0;
      margin: 0;
      margin-bottom: rem(60px);
      list-style: none;

      @include mq($from: small-desktop) {
        margin: 0;
        margin-top: rem(50px);
        justify-content: start;
      }

      .social-icon__item:not(:last-child) {
        margin-right: rem(30px);

        @include mq($from: small-desktop) {
          margin-right: rem(8px);
        }

        @include mq($until: small-tablet) {
          margin-right: rem($space-md-size);
        }
      }

      .social-icon__link {
        @include flex-center;
        width: rem(64px);
        height: rem(64px);
        color: var(--white);
        background-color: $icon-stroke-color-4;
        font-size: rem(24px);
        border: none;
        border-radius: 50%;
        transition: color $transition-time, background-color $transition-time;

        @include mq($from: small-desktop) {
          width: rem(38px);
          height: rem(38px);
          font-size: rem(18px);
        }
      }

      .social-icon__link:hover {
        color: $icon-stroke-color-5;
        background-color: var(--white);
        border: none;
        box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.09);
      }
    }
  }

  .footer-container::after {
    position: absolute;
    content: '';
    bottom: rem(240px);
    left: rem(0);
    width: 100%;
    height: rem(2px);
    @include mq($from: small-desktop) {
      background-color: var(--secondary-border-color);
    }

    @include mq($until: small-desktop) {
      bottom: rem(695px);
      box-shadow: inset 0px 1px 0px var(--primary-border-color);
    }
  }
}

.layout__sider-menu {
  &--sitemap {
    ul {
      li::before {
        content: '\2022';
        color: var(--footer-navigation-title);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-right: rem($space-xs-size);
      }

      ul li::before {
        margin-left: rem($space-xs-size + 15px);
      }
    }
  }

  .sidebar-container {
    max-width: 100%;
    height: 100%;
    font-family: $default-font;
    background-color: var(--white);
    border-radius: rem(8px);
    overflow: hidden;
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);

    &__item {
      cursor: pointer;
      width: 100%;
      padding: rem(16px $space-lg-size);
      box-shadow: inset 0px -1px 0px var(--primary-border-color);
      transition: background-color $transition-time;
      font-weight: 500;
      font-size: rem($space-md-size);
      line-height: rem(22px);
      color: var(--primary-side-menu-color);

      &:hover:not(&--static) {
        background-color: rgba($active-side-menu-background-color, 0.1);
      }

      &--static {
        cursor: initial;
      }
    }

    .active-item {
      background-color: var(--active-side-menu-background-color);
      color: var(--white);
    }

    &__item-children {
      display: none;

      ul {
        padding: 0;
        margin: 0;

        li {
          @include flex-center-vert;
          cursor: pointer;
          color: var(--active-breadcrumbs-color);
          font-weight: 500;
          font-size: rem(14px);
          min-height: rem(40px);
          padding: rem(9px) rem($space-lg-size) rem(9px) rem(24px);
          transition: background-color $transition-time;

          &:hover {
            background-color: rgba($active-side-menu-background-color, 0.1);
          }

          &.active-url {
            border-right: 3px solid var(--active-side-menu-border-color);
            color: var(--active-side-menu-background-color);
          }
        }

        li:hover {
          color: var(--active-side-menu-background-color);
        }

        ul {
          padding: 0;
          margin: 0;
          @include mq($from: small-desktop) {
            box-shadow: inset 0px 3px 7px rgba(0, 0, 0, 0.09);
          }

          li::before {
            content: '\2022';
            color: var(--footer-navigation-title);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-right: rem($space-xs-size);
          }
        }
      }
    }

    .active-child {
      display: block !important;
    }

    &__news-item {
      .news-item__title {
        padding: rem(17px 20px);
        font-weight: 600;
        font-size: rem(20px);
        line-height: rem(32px);
        color: var(--secondary-text-color);
        border-bottom: 1px solid var(--primary-border-color);
      }

      .news-item__button {
        padding: rem(30px 20px);
      }
    }
  }

  .collapse-header .sidebar-container__item {
    margin-left: rem(-20px);
    padding-left: rem(25px);
    width: calc(100% + 25px);
    box-shadow: unset;
  }

  .collapse-body {
    padding: 0;
  }
}

.card-header__box {
  display: block;
  width: 100%;
  height: 100%;

  .title-block {
    @include flex;
    flex-wrap: wrap;

    &__text {
      @include flex-center;
      width: rem(95px);
      margin-right: rem($space-xs-size);
      margin-bottom: rem($space-xs-size);
      border-radius: rem(4px);

      .title-text {
        margin: 0;
        padding: rem(4px 7px);
        color: var(--secondary-text-color);
        font-family: $second-font;
        font-weight: 600;
        font-size: rem($default-font-size);
        line-height: rem(21px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__text--width {
      width: rem(160px);
    }

    .news {
      background-color: var(--card-background-3);
    }

    .announces {
      background-color: var(--card-background-1);
    }

    .warnings {
      background-color: var(--primary-border-color);
    }

    .promotions {
      background-color: var(--card-background-7);
    }

    &__date {
      @include flex-center;
      margin: 0;
      height: rem(29px);
      color: var(--active-side-menu-background-color);
      margin-bottom: rem(12px);
      font-family: $second-font;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(24px);
    }
  }
}

//news-item
.section-image__news {
  @include background-size-cover;
  max-height: 750px;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--primary-border-color);
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);
  border-radius: rem(8px);
}

.news-item__subtitle {
  font-weight: 500;
  font-size: rem(15px);
  line-height: rem(22px);

  color: var(--primary-side-menu-color);
}

.section-text__content {
  margin: 0;
  font-family: $second-font;
  font-weight: 400;
  font-size: rem(16px);
  line-height: rem(27px);
  color: var(--primary-text-color);

  // collapse
  details.pre {
    box-sizing: border-box;
    background-color: $white;
    border: 1px solid var(--primary-border-color);
    border-radius: rem(8px);

    .pre-content {
      white-space: normal !important;
    }

    &:hover,
    &[open] {
      summary.pre-title {
        &::before {
          filter: invert(1);
        }

        &::after {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            var(--active-side-menu-background-color);
        }
      }
    }

    &[open] {
      .pre-content {
        padding-top: rem($space-xs-size) !important;
        padding-bottom: rem($space-lg-size) !important;
      }

      summary.pre-title {
        &::before {
          transform: translate3d(0, -50%, 0) rotate(-90deg);
        }
      }
    }
  }

  summary.pre-title {
    @include flex-center-between;
    position: relative;
    min-height: rem(62px);
    padding: rem($space-md-size 25px);
    padding-right: rem(65px) !important;
    cursor: pointer;
    font-weight: 600;

    &::before {
      content: '';
      background-image: url('../../resources/icons/select_right_arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: rem(32px);
      width: rem(32px);
      position: absolute;
      right: rem(25px);
      top: 50%;
      border-radius: 50%;
      transform: translate3d(0, -50%, 0);
      transition: transform $collapse-transition-time;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      height: rem(32px);
      width: rem(32px);
      right: rem(25px);
      top: 50%;
      background-color: var(--section-background-2);
      border-radius: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }

  .pre-content {
    margin: 0 !important;
    padding-left: rem($space-xxl-size) !important;
    padding-right: rem($space-xxl-size) !important;
    font-family: unset !important;
    overflow: hidden !important;
    transition: all $transition-time;
  }
  // collapse

  a.is-pretty {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    max-width: rem(400px);
    flex: 0 0 calc(50% - 20px);
    margin: rem(0 10px 35px 10px);
    padding: rem(25px);
    padding-top: rem(80px);
    background: $white;
    border: 1px solid #eef1f2;
    box-sizing: border-box;
    box-shadow: rem(0px 7px 15px) rgba(0, 0, 0, 0.07);
    border-radius: rem(8px);
    font-weight: 600;
    color: $active-side-menu-background-color;
    font-size: rem(18px);
    line-height: rem(28px);
    color: background-color $transition-time;
    font-family: Rubik;
    font-style: normal;
    letter-spacing: 0.005em;

    &::before {
      @include flex-center;
      position: absolute;
      top: 23px;
      content: url(~/src/resources/icons/list.svg);
      width: rem(48px);
      height: rem(48px);
      font-size: rem(18px);
      margin-bottom: rem(20px);
      border-radius: rem(100px);
      transition: filter $transition-time;
      z-index: 2;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: rem(23px);
      @include flex-center;
      width: rem(48px);
      height: rem(48px);
      background: #f6f6f6;
      border-radius: rem(100px);
      transition: background-color $transition-time;
    }

    &:hover {
      color: $icon-stroke-color-6;

      &::before {
        filter: brightness(0) invert(1);
      }

      &::after {
        background-color: $point-red;
        color: $white;
      }
    }
  }

  img {
    max-width: 100%;
    display: unset;
  }

  img[style*='float:right'] {
    padding-left: rem($space-md-size);
  }

  img[style*='float:left'] {
    padding-right: rem($space-md-size);
  }

  .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }

  /* ckeditor5-image/theme/imageresize.css */
  .image.image_resized img {
    width: 100%;
  }

  /* ckeditor5-image/theme/imageresize.css */
  .image.image_resized > figcaption {
    display: block;
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }

  /* ckeditor5-highlight/theme/highlight.css */
  .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }

  /* ckeditor5-image/theme/imagecaption.css */
  .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }

  /* ckeditor5-font/theme/fontsize.css */
  .text-tiny {
    font-size: 0.7em;
  }

  /* ckeditor5-font/theme/fontsize.css */
  .text-small {
    font-size: 0.85em;
  }

  /* ckeditor5-font/theme/fontsize.css */
  .text-big {
    font-size: 1.4em;
  }

  /* ckeditor5-font/theme/fontsize.css */
  .text-huge {
    font-size: 1.8em;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-block-align-left,
  .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-left,
  .image-style-align-right {
    clear: none;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  p + .image-style-align-left,
  p + .image-style-align-right,
  p + .image-style-side {
    margin-top: 0;
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-inline.image-style-align-left,
  .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
  }

  /* ckeditor5-image/theme/imagestyle.css */
  .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
  }

  /* ckeditor5-image/theme/image.css */
  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: rem(50px);
  }

  /* ckeditor5-image/theme/image.css */
  .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
  }

  /* ckeditor5-image/theme/image.css */
  .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
  }

  /* ckeditor5-image/theme/image.css */
  .image-inline picture {
    display: flex;
  }

  /* ckeditor5-image/theme/image.css */
  .image-inline picture,
  .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
  }

  /* ckeditor5-horizontal-line/theme/horizontalline.css */
  hr {
    margin: rem(15px 0);
    height: rem(4px);
    background: hsl(0, 0%, 87%);
    border: 0;
  }

  /* ckeditor5-block-quote/theme/blockquote.css */
  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
  }

  /* ckeditor5-block-quote/theme/blockquote.css */
  .ck-content[dir='rtl'] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
  }

  /* ckeditor5-basic-styles/theme/code.css */
  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: rem(2px);
  }

  /* ckeditor5-table/theme/tablecaption.css */
  .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }

  /* ckeditor5-table/theme/table.css */
  .table {
    margin: 0.9em auto;
    display: table;
  }

  /* ckeditor5-table/theme/table.css */
  .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
  }

  /* ckeditor5-table/theme/table.css */
  .table table td,
  .table table th {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid hsl(0, 0%, 75%);
  }

  /* ckeditor5-table/theme/table.css */
  .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
  }

  /* ckeditor5-table/theme/table.css */
  .ck-content[dir='rtl'] .table th {
    text-align: right;
  }

  /* ckeditor5-table/theme/table.css */
  .ck-content[dir='ltr'] .table th {
    text-align: left;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: rem(2px);
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* ckeditor5-media-embed/theme/mediaembed.css */
  .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list {
    list-style: none;
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list li {
    margin-bottom: 5px;
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list li .todo-list {
    margin-top: rem(5px);
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: rem(-25px);
    margin-right: rem(-15px);
    right: 0;
    margin-left: 0;
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: rem(2px);
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
      calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
  }

  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
  }

  /* ckeditor5-language/theme/language.css */
  span[lang] {
    font-style: italic;
  }

  /* ckeditor5-code-block/theme/codeblock.css */
  pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: rem(2px);
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: rem(200px);
  }

  /* ckeditor5-code-block/theme/codeblock.css */
  pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
  }

  /* ckeditor5-mention/theme/mention.css */
  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }

  ul {
    margin-left: rem(20px);
  }

  @media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .page-break {
      padding: 0;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .page-break::after {
      display: none;
    }
  }

  .item__box > .background-gradient {
    position: relative;

    .item-title {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      left: 0;

      > a {
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding-left: rem($space-lg-size);
        padding-bottom: rem($space-md-size);
      }
    }
  }
}

.section-bg {
  @include mq($until: small-desktop) {
    background-color: var(--section-background-2);
  }

  @include mq($from: small-desktop) {
    padding-top: rem($space-md-size);
    background-color: var(--section-background-1);
  }
}

.section-bg__image {
  @include background-size-cover;
}

//presentation page
.section-image__presentation {
  @include background-size-cover;
  height: rem(220px);
  border-radius: rem(12px);
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .background-gradient {
    height: 100%;
    background: $background-gradient-4;
  }

  .section-box {
    position: absolute;
    bottom: rem(20px);
    left: rem(35px);

    &__title {
      margin: 0;
      margin-bottom: rem(5px);
      font-weight: 600;
      font-size: rem(24px);
      line-height: rem(38px);
      color: var(--white);
    }

    &__text {
      margin: 0;
      margin-right: rem(3px);
      font-family: $second-font;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(24px);
      color: var(--white);
    }
  }

  .section-box > div {
    @include flex-center-vert;

    svg {
      font-size: rem(12px);
      color: var(--white);
    }
  }

  &:hover {
    .section-box > div {
      svg {
        color: var(--card-background-5);
      }
    }
  }
}

//auction page  // include styles presentation page
.section__title {
  font-weight: 700;
  font-size: rem(36px);
  line-height: rem(48px);
  color: var(--secondary-text-color);
}

.section__subtitle {
  font-weight: 700;
  font-size: rem(24px);
  line-height: rem(36px);
  font-family: $default-font;
  color: var(--primary-text-color);
}

.background-gradient-auction {
  height: 100%;
  background: $background-gradient-3;
}

// history page // include styles presentation page
.section-image__history {
  @include background-size-cover;
  height: rem(380px);
  border-radius: rem(8px);
  overflow: hidden;
  position: relative;
  // cursor: pointer; // Uncomment this if need modal preview for background image

  .background-gradient {
    height: 100%;
    background: $background-gradient-4;
  }

  .section-box {
    position: absolute;
    bottom: rem(35px);
    left: rem(35px);

    &__title {
      margin: 0;
      font-weight: 600;
      font-size: rem(36px);
      line-height: rem(48px);
      color: var(--white);
    }
  }
}

.section-history__subtitle {
  margin: 0;
  font-family: $default-font;
  font-weight: 600;
  font-size: rem(24px);
  line-height: rem(36px);
  color: var(--primary-text-color);
}

.section-history__image {
  @include background-size-cover;
  width: rem(320px);
  height: rem(262px);
}

.section-history__image-today {
  @include background-size-cover;
  width: rem(320px);
  height: rem(145px);
}

//structure page //include styles presentation page
.structure-tab__nav {
  padding: rem($space-xs-size $space-lg-size);

  .structure-tab__title {
    margin: 0;
    font-weight: 600;
    font-size: rem(20px);
    line-height: rem(30px);
    color: var(--primary-side-menu-color);
    text-align: center;
  }

  &:hover {
    .structure-tab__title {
      color: var(--secondary-text-color);
    }
  }
}

.complaint-petition__subtitle {
  margin: 0;
  font-family: $default-font;
  font-weight: 700;
  font-size: rem(24px);
  line-height: rem(36px);
  color: var(--secondary-text-color);
}

@keyframes headerAlert {
  from {
    padding: 0;
  }
  to {
    padding: rem(math.div($base-size, 2.5) 0);
  }
}

.no-scroll {
  overflow: hidden;
}

.portal-image {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  z-index: 99;
  background-color: #01020282;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .portal-image__close {
    position: absolute;
    right: rem(5px);
    top: rem(5px);
    width: rem(50px);
    height: rem(50px);
    text-align: center;

    &::after {
      content: '\d7';
      font-size: rem(35px);
      color: #fff;
      display: block;
      cursor: pointer;
    }
  }
}

.section-text__content {
  .navigation-settings__item {
    @include mq($until: small-desktop) {
      .item__box {
        height: rem(220px);
        border-radius: rem(12px);
        .background-gradient {
          height: 100%;
          width: 100%;
          background: $background-gradient-2;
          .from--small-desktop {
            display: unset;
          }
        }
      }
      .item-title {
        font-weight: 500;
        color: var(--white);
      }
      .item__link {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .btgrid {
    @include mq($until: small-desktop) {
      .row.row-1 {
        justify-content: center;
        .col.col-md-3 {
          flex: 0 0 auto;
          width: 100%;
          margin-bottom: rem(5px);
        }
        .col.col-md-4 {
          flex: 0 0 auto;
          width: 100%;
          margin-bottom: rem(5px);
        }
        .col.col-md-3:last-child,
        .col.col-md-4:last-child {
          margin-right: rem(11px);
        }
      }
    }
  }
}
