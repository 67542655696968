.social-network {
  height: 100%;
  width: 100%;
  padding: rem(25px);

  background-color: var(--white);
  border: 1px solid var(--primary-border-color);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
  border-radius: rem(6px);
  @include mq($from: small-tablet) {
    @include flex-center-between;
  }

  &__text {
    margin-bottom: rem($space-md-size);
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(28px);
    color: var(--secondary-text-color);

    @include mq($from: small-tablet) {
      margin: 0;
    }
  }

  &__list {
    @include flex-center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item:not(:last-child) {
    margin-right: rem($space-lg-size);
  }

  &__link {
    @include flex-center;
    width: rem(48px);
    height: rem(48px);
    font-size: rem(24px);
    background-color: var(--section-background-2);
    border: 1px solid var(--primary-border-color);
    border-radius: 50%;

    &:hover {
      background-color: var(--white);
      border: none;
      box-shadow: 0px 12px 19px rgba(0, 0, 0, 0.09);
    }
  }

  .facebook {
    color: $icon-stroke-color-7;
  }
  .twitter {
    color: $icon-stroke-color-8;
  }
  .linkedIn {
    color: $icon-stroke-color-9;
  }
}
