.periodic-editions {
  font-family: $default-font;
}

.periodic-edition-description {
  line-height: 24px;
  font-size: 14px;
  color: var(--gray-400);
}

.periodic-edtion-subscription-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.periodic-edtion-subscription-radio,
.periodic-edtion-subscription-checkbox-wrapper {
  border: 1px solid var(--light-gray-400);
  padding: 10px 16px;
  border-radius: 6px;
  background-color: white;
}

.periodic-edtion-subscription-radio {
  flex: 1;
  margin-right: 0 !important;
}

.periodic-edition-characteristics-item {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 8px 0;

  &__label {
    color: var(--gray-400);
  }
  &__value {
    color: var(--secondary-text-color);
    font-weight: 500;
    text-align: right;
  }
}

.periodic-edition-quantity-input {
  input {
    font-size: 18px;
    padding: 24px;
    width: 100px;
  }
}

.periodic-edition-total {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  font-size: 18px;
  font-weight: 600;
  color: var(--active-side-menu-background-color);
}
