@import './SelectWithScroll.scss';

.select-dropdown.react-dropdown-select {
  width: 100%;
  height: rem(47px);
  border: rem(1px) solid var(--footer-navigation-links);
  border-radius: rem(8px);

  div:nth-child(1) {
    padding: 0;
    overflow: hidden;

    @include mq($from: small-tablet) {
      padding-left: rem(10px);
    }

    span {
      margin: 0;
      max-width: 90%;
      width: auto;
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: 600;
      color: var(--primary-text-color);

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    input {
      margin: 0;
      font-family: $default-font;
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(24px);
      color: var(--footer-navigation-title);
    }

    input::placeholder {
      font-family: $default-font;
      color: var(--input-placeholder);
      font-size: rem(16px);
      font-weight: 500;
    }
  }

  .react-dropdown-select-clear {
    margin: 0;
    padding: rem(5px 0);

    &:hover,
    &:focus {
      color: var(--icon-stroke-color-10);
    }
  }

  .react-dropdown-select-dropdown-handle {
    margin-left: rem(3px);
    padding: rem(5px 0);
  }

  .react-dropdown-select-dropdown {
    .react-dropdown-select-item-selected {
      background-color: var(--icon-stroke-color-6);
      color: var(--white);
    }

    .react-dropdown-select-no-data {
      padding: rem($space-xs-size);
      color: var(--icon-stroke-color-6);
    }

    .select-no-data {
      padding: rem($space-xs-size);
      color: var(--icon-stroke-color-6);
    }
  }

  &:hover,
  &:focus {
    border-color: var(--active-side-menu-background-color);
  }

  &:focus-within {
    border-color: var(--active-side-menu-background-color);
  }
}
