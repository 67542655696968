.product-gallery {
  width: 100%;
  overflow: hidden;
  .slick-list {
    z-index: initial;
  }
  .gallery-images {
    &.slick-slider {
      height: rem(420px);
    }
    .gallery__image {
      cursor: pointer;
    }
  }
}
