.drop-zone {
  width: 100%;
  height: rem(57px);
  @include flex-center-vert;
  padding: rem($space-lg-size);
  background-color: var(--white);
  border: 1px solid var(--footer-navigation-links);
  border-radius: rem(8px);
  cursor: pointer;

  &__icon {
    @include flex;
    margin-right: rem($space-sm-size);
    color: var(--active-side-menu-background-color);
  }

  &__title {
    font-family: $default-font;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(21px);
    width: 100%;
    color: var(--active-side-menu-background-color);
  }

  &__description {
    font-family: $default-font;
    font-weight: normal;
    font-size: rem(11px);
    line-height: rem(14px);
    width: 100%;
  }

  &:hover {
    border: 1px solid var(--active-side-menu-background-color);
  }
}
